import { gql } from '@apollo/client';

export const GET_SAVED_SEARCHES = gql`
  query LoadSavedSearches {
    savedSearches(sort: ["createdAt:DESC"], pagination: { limit: 100 }) {
      data {
        id
        attributes {
          alertFrequency
          name
          minPrice
          maxPrice
          minBeds
          maxBeds
          baths
          parkingPlaces
          minSqft
          maxSqft
          minYearBuild
          maxYearBuild
          petFriendly
          daysOnCbb
          closedAt
          bitcoin
          sellerFinancing
          shortTermRent
          furnished
          unfurnished
          intracoastalView
          oceanView
          skylineView
          beachfront
          oceanfront
          wfAny
          penthouse
          keyWords
          showPendingListings
          maxHOA
          corner
          minFloor
          maxFloor
          minPriceSqFt
          maxPriceSqFt
          unitNumberRegex
          minUnitsInBuilding
          maxUnitsInBuilding
          rentalInvestments
          lowDownPayment
          secretSearch
          zipCodes {
            code
          }
          shortTermRentTerms {
            term
          }
          preConstruction
          newConstruction
          forRent
          forSale
          foreclosures
          shortSales
          existingCondos
          sold
          counties {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          cities {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          neighborhoods {
            data {
              id
              attributes {
                name
                slug
                city {
                  data {
                    id
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
          buildings {
            data {
              id
              attributes {
                name
                slug
                neighborhood {
                  data {
                    id
                    attributes {
                      name
                      slug
                      city {
                        data {
                          id
                          attributes {
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          addresses {
            data {
              id
              attributes {
                primaryAddress
                name
                neighborhood {
                  data {
                    id
                    attributes {
                      name
                      slug
                      city {
                        data {
                          id
                          attributes {
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
        }
      }
    }
  }
`;
