import { AuthenticatedUserInfo } from '../../apolloClient/types';

export const initialAuthData: AuthenticatedUserInfo = {
  jwt: '',
  user: {
    id: null,
    username: '',
    assignedLead: {
      id: 0,
    },
  },
};

export const AUTH_DATA = 'authData';
export const LOGIN_AS_USER = 'loginAsUser';
