import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import { Dialog, styled } from '@mui/material';
import Image from 'next/legacy/image';

import {
  isNotEmpty,
  validateEmail,
  switchWasOnceLogged,
  getAgentIdFromLocalStorage,
  getLeadIdFromLocalStorage,
  getbrowserUUIDFromLocalStorage,
  saveLeadIdToLocalStorage,
  wasOnceLogged,
  removeQueryParams,
  getATTFromLocalStorage,
} from 'src/utils/utils';
import { updateAuthDataInCookies } from 'src/utils/authUtils';
import { initialAuthData } from 'src/constants/authConstants';
import {
  AUTH_LOGIN,
  REGISTER,
  VERIFY_EMAIL,
  // VERIFY_RECAPTCHA,
} from 'apolloClient/mutations/auth';
import { GET_LAST_LISTING_UPDATED } from 'apolloClient/queries/header';
import { Response } from 'apolloClient/types/common';
import { AuthenticatedUserInfo } from 'apolloClient/types';
import PopUpThanksForRegistration from 'components/PopUpThanksForRegistration';

import iconClose from '../../../public/images/close.svg';
import FlagLabel from '../../FlagLabel';
import { useAuth } from '../AuthProvider';
import {
  FormDataInterface,
  FormMode,
  SubmitStateInterface,
  FieldsValidInterface,
} from './interfaces';
import LoginMode from './loginMode';
import RegisterMode from './registerMode';
import ResetPasswordMode from './resetPasswordMode';
import { useRouter } from 'next/router';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MOVE_ACTIVITIES } from 'apolloClient/mutations/activities';
import { UNIT_DISPLAY_COUNTER } from 'src/constants/constants';
import { ADD_FUB_TAG } from 'apolloClient/mutations/followUpBoss';

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-container': {
    '.MuiPaper-root': {
      maxWidth: '500px',
      maxHeight: 'none',
      margin: '0',
      borderRadius: '0',
    },
  },
  '@media (max-width:767px)': {
    '.MuiDialog-container': {
      '.MuiPaper-root': {
        width: '100%',
      },
    },
  },
}));

const initialFormData: FormDataInterface = {
  FullName: '',
  Password: '',
  Email: '',
  showPassword: false,
  RegistrationSource: '',
  isInvalidPassword: false,
  isRegisterError: false,
  isNeedPwdReset: false,
  isNeedShowLegacyMessage: false,
  isCaptchaValidationFailed: false,
};

const PopUpJoinOrSignIn: React.FC = () => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [formMode, setFormMode] = useState<FormMode>(FormMode.REGISTER);
  const [formData, setFormData] = useState(initialFormData);
  const [submitState, setSubmitState] = useState<SubmitStateInterface>({
    isTouched: false,
  });
  const [fieldsValid, setFieldsValid] = useState<FieldsValidInterface>({
    Email: true,
    Password: true,
    FullName: true,
  });

  const [sendLoginRequest, loginRequestDetails] =
    useMutation<{ login: AuthenticatedUserInfo }>(AUTH_LOGIN);
  const [sendRegisterRequest, registerRequestDetails] = useMutation(REGISTER);
  const [sendVerifyEmailRequest] = useLazyQuery(VERIFY_EMAIL, {
    fetchPolicy: 'no-cache',
  });

  // const [sendVerifyRecaptchaRequest] = useLazyQuery(VERIFY_RECAPTCHA, {
  //   fetchPolicy: 'no-cache',
  // });

  const [moveActivitiesToUser] = useMutation(MOVE_ACTIVITIES);

  const [fetchLastUpdated, { data }] = useLazyQuery<{
    lastListingUpdate: Response<Record<'lastListingUpdate', string>>;
  }>(GET_LAST_LISTING_UPDATED, { fetchPolicy: 'network-only' });

  const {
    onLoggedIn,
    onSuccess: onPopupSuccess,
    onFinnaly,
    keepOpen,
    closeHeaderPopup,
    headerPopupOpen,
    updateMe,
    me,
  } = useAuth();

  const router = useRouter();

  useEffect(() => {
    const email = router.query.loginEmail;
    if (email) {
      removeQueryParams(['loginEmail'], router);
      setFormData((formData) => ({ ...formData, Email: email as string }));
      verifyEmail(email as string);
    }
  }, [router]);

  const authFormLoading =
    registerRequestDetails.loading || loginRequestDetails.loading;

  const isRegisterMode = formMode === FormMode.REGISTER;
  const isLoginMode = formMode === FormMode.LOGIN;
  const isResetPasswordMode = formMode === FormMode.RESET_PASSWORD;
  const isSuccessMode = formMode === FormMode.SUCCESS;

  useEffect(() => {
    headerPopupOpen && setFormMode(FormMode.LOGIN);
    headerPopupOpen && setFormData(initialFormData);
    headerPopupOpen && setSubmitState({ isTouched: false });
  }, [headerPopupOpen]);

  const checkForRent = () => {
    if (router.query.hasOwnProperty('forRent')) {
      return router.query['forRent'] == 'true' ? 'RENT' : '';
    }
    return '';
  };

  useEffect(() => {
    if (isLoginMode) {
      fetchLastUpdated();
    }
    if (isRegisterMode) {
      setFormData((formData) => ({
        ...formData,
        RegistrationSource: checkForRent(),
      }));
    }
  }, [fetchLastUpdated, formMode]);

  const closePopup = () => {
    closeHeaderPopup();
    if (isSuccessMode) {
      setFormData((formData) => ({
        ...formData,
        showRegisterSuccessPopup: false,
      }));
    } else {
      setFormData(initialFormData);
    }
    onFinnaly && onFinnaly();
  };

  const validateFullname = () => {
    const fullname = formData.FullName?.trim();
    if (!isNotEmpty(fullname)) return false;
    return /(?=^.{0,40}$)^[a-zA-Z-]+\s[a-zA-Z-]+$/.test(fullname);
  };

  const validatePwd = () => {
    const pwd = formData.Password?.trim();
    if (!isNotEmpty(pwd)) return false;
    if (pwd.length < 6) return false;
    return true;
  };

  const validateFields = () => {
    let emailValid = validateEmail(formData.Email);
    let passwordValid =
      (!isRegisterMode && !formData.showPassword) || validatePwd();
    let fullNameValid = !isRegisterMode || validateFullname();
    setSubmitState({ ...submitState, isTouched: true });
    setFieldsValid({
      ...fieldsValid,
      Email: emailValid,
      Password: passwordValid,
      FullName: fullNameValid,
    });

    return emailValid && passwordValid && fullNameValid;
  };

  function updateField(fieldName: string, fieldValue: string) {
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
      isInvalidPassword: false, //resetting to false - to disappear 'invalid password' message from server
    });
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateField(e.target.name, e.target.value);
    if (submitState.isTouched) {
      if (e.target.name == 'Password') {
        let valid =
          (!isRegisterMode && !formData.showPassword) ||
          isNotEmpty(e.target.value);
        setFieldsValid({ ...fieldsValid, [e.target.name]: valid });
      } else if (e.target.name == 'FullName') {
        let valid = !isRegisterMode || isNotEmpty(e.target.value);
        setFieldsValid({ ...fieldsValid, [e.target.name]: valid });
      } else if (e.target.name == 'Email') {
        let valid = validateEmail(e.target.value);
        setFieldsValid({ ...fieldsValid, Email: valid });
      }
    }
  };

  const revertToStartStage = () => {
    setFormData({
      ...initialFormData,
      Email: formData.Email,
    });
  };

  const verifyEmail = async (email: String = formData.Email.toLowerCase()) => {
    const {
      data: {
        verifyEmail: { ok: isEmailValid, isLostLegacyPwd },
      },
    } = await sendVerifyEmailRequest({
      variables: {
        email,
      },
    });

    setFormData((formData) => ({
      ...formData,
      showPassword: isEmailValid,
      isNeedPwdReset: isLostLegacyPwd,
      isNeedShowLegacyMessage: isLostLegacyPwd !== null && !wasOnceLogged(),
    }));

    if (!isEmailValid) {
      setFormMode(FormMode.REGISTER);
    }

    setSubmitState((prevState) => ({
      ...prevState,
      isTouched: false,
    }));
  };

  function updateAuthUserData(
    payload: AuthenticatedUserInfo,
    type: 'login' | 'register'
  ) {
    const { jwt, user } = payload;
    const jwtNotEmpty = isNotEmpty(jwt);

    if (jwtNotEmpty) {
      updateAuthDataInCookies({ user, jwt });
    }

    if (type === 'login') {
      setFormData((formData) => ({
        ...formData,
        Password: jwtNotEmpty ? '' : formData.Password,
        Email: jwtNotEmpty ? '' : formData.Email,
        showPassword: !jwtNotEmpty,
        isInvalidPassword: !jwtNotEmpty,
        isCaptchaValidationFailed: false,
      }));
      if (jwtNotEmpty) {
        closePopup();
      }
    }
    if (type === 'register') {
      setFormData((formData) => ({
        ...formData,
        Password: jwtNotEmpty ? '' : formData.Password,
        isRegisterError: !jwtNotEmpty,
        isCaptchaValidationFailed: false,
      }));
      if (jwtNotEmpty) {
        setFormMode(FormMode.SUCCESS);
      }
    }
    setSubmitState((prevState) => ({ ...prevState, isTouched: !jwtNotEmpty }));
    onLoggedIn &&
      onLoggedIn({
        jwt: jwtNotEmpty ? jwt : initialAuthData.jwt,
        user: jwtNotEmpty ? { ...user } : { ...initialAuthData.user },
      });
  }

  function onSuccess(logged = true) {
    logged && switchWasOnceLogged();
    onPopupSuccess && onPopupSuccess();
  }

  async function register() {
    const agentId = getAgentIdFromLocalStorage();
    const leadId = getLeadIdFromLocalStorage();
    const context = {
      headers: {
        ...(agentId
          ? { 'follow-up-boss-agent': getAgentIdFromLocalStorage() }
          : {}),
        ...(leadId
          ? { 'follow-up-boss-lead': getLeadIdFromLocalStorage() }
          : {}),
      },
    };

    // const token = (await onReCaptchaVerify()) || '';

    // const {
    //   data: {
    //     verifyRecaptcha: { ok: isRecaptchaValid },
    //   },
    // } = await sendVerifyRecaptchaRequest({
    //   variables: {
    //     token: token,
    //   },
    // });

    // if (!isRecaptchaValid) {
    //   setFormData((formData) => ({
    //     ...formData,
    //     isCaptchaValidationFailed: true,
    //   }));
    //   return;
    // }

    const checkPPCUser = () => {
      const isUnitPage = router.query.hasOwnProperty('urlUnitParam');
      const att = getATTFromLocalStorage() || 0;
      const displayCounter = localStorage.getItem(UNIT_DISPLAY_COUNTER) || -1;
      return isUnitPage && +att <= +displayCounter;
    };

    const browserUUID = getbrowserUUIDFromLocalStorage();

    sendRegisterRequest({
      context,
      variables: {
        input: {
          password: formData.Password.trim(),
          email: formData.Email.trim(),
          username: formData.Email.trim(),
          phoneNumber: formData.Password.trim(),
          notificationPhone: formData.Password.trim(),
          fullName: formData.FullName.trim(),
          registrationSource: formData.RegistrationSource?.trim() || '',
          ppcUser: checkPPCUser(),
          browserUUID: browserUUID || undefined,
        },
      },
    })
      .then((res) => {
        if (res.data.register.jwt) {
          updateAuthUserData(
            res.data.register as AuthenticatedUserInfo,
            'register'
          );
        } else if (res.data.register.user) {
          setFormMode(FormMode.SUCCESS);
        }

        moveActivitiesToUser({
          variables: {
            email: formData.Email.trim(),
            browserUUID,
            isRegister: true,
          },
        });
        onSuccess(false);
      })
      .catch((error) => console.error(error));
  }

  function login() {
    sendLoginRequest({
      variables: {
        input: {
          identifier: formData.Email.trim(),
          password: formData.Password.trim(),
        },
      },
    })
      .then((res) => {
        updateAuthUserData(res.data?.login as AuthenticatedUserInfo, 'login');
        saveLeadIdToLocalStorage(String(res.data?.login.user.assignedLead?.id));
        const browserUUID = getbrowserUUIDFromLocalStorage();
        moveActivitiesToUser({
          variables: {
            email: res.data?.login.user.username,
            browserUUID,
            isRegister: false,
          },
        });
        onSuccess();
      })
      .catch((error) => {
        if (error.message === 'Your account email is not confirmed') {
          setFormMode(FormMode.SUCCESS);
          return;
        }

        if (
          error?.graphQLErrors?.find(
            ({ message }: { message: string }) =>
              message === 'Invalid identifier or password'
          )
        ) {
          updateAuthUserData(
            { jwt: '', user: { id: 0, username: '', assignedLead: { id: 0 } } },
            'login'
          );
        }
      });
  }
  const [dataType, setDataType] = useState(false);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (validateFields()) {
      if (isLoginMode && formData.showPassword) {
        login();
      } else if (isLoginMode) {
        verifyEmail();
      } else if (isRegisterMode) {
        setDataType(true);
        register();
      }
    }
  };

  const revertToLoginMode = () => {
    setFormData((formData) => ({
      ...formData,
      FullName: '',
      Password: '',
      isInvalidPassword: false,
      isRegisterError: false,
    }));
    setFormMode(FormMode.LOGIN);
    setSubmitState((submitState) => ({
      ...submitState,
      isTouched: false,
    }));
  };

  const goToResetPasswordMode = () => {
    setFormData((formData) => ({
      ...formData,
      FullName: '',
      Password: '',
      isInvalidPassword: false,
      isRegisterError: false,
    }));
    setFormMode(FormMode.RESET_PASSWORD);
    setSubmitState((submitState) => ({
      ...submitState,
      isTouched: false,
    }));
  };

  // const onReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) return;

  //   const token = await executeRecaptcha('login_register');
  //   return token;
  // }, [executeRecaptcha]);

  const [displayIconClose, setDisplayIconClose] = useState(true);

  useEffect(() => {
    const attValue = localStorage.getItem('att');
    const counterValue = localStorage.getItem('unitDisplayCounter');

    if (!!attValue && !!counterValue && +counterValue >= +attValue) {
      setDisplayIconClose(false);
    } else {
      setDisplayIconClose(true);
    }
  }, [closeHeaderPopup]);
  const isUnitPage = router.query.hasOwnProperty('urlUnitParam');
  return (
    <StyledDialog
      disableRestoreFocus
      open={headerPopupOpen}
      onClose={() => {
        me?.id && closeHeaderPopup();
        displayIconClose && !isUnitPage && closeHeaderPopup();
        isUnitPage && undefined;
      }}
    >
      <div className="h-screen p-10 bg-white border border-gray md:h-full md:w-full">
        {isLoginMode || isRegisterMode || isSuccessMode ? (
          <div className="flex flex-row items-center justify-between mb-6">
            <div className="relative flex flex-col justify-center w-max">
              <FlagLabel name={isSuccessMode ? 'Welcome' : 'Join or sign in'} />
            </div>
            {!isSuccessMode && displayIconClose && (
              <button
                className="z-50 outline-none"
                onClick={() => closePopup()}
              >
                <Image
                  src={iconClose}
                  alt="close popup"
                  width={15}
                  height={15}
                />
              </button>
            )}
          </div>
        ) : (
          isResetPasswordMode && (
            <div className="flex flex-row items-center justify-between mb-6">
              <div className="relative flex flex-col justify-center w-max">
                <FlagLabel name="Reset password" />
              </div>
              <button className="z-50 outline-none" onClick={revertToLoginMode}>
                <Image
                  src={iconClose}
                  alt="close popup"
                  width={15}
                  height={15}
                />
              </button>
            </div>
          )
        )}
        {isLoginMode ? (
          <LoginMode
            data={data}
            onFormSubmit={onFormSubmit}
            handleChange={handleChange}
            formData={formData}
            submitState={submitState}
            fieldsValid={fieldsValid}
            authFormLoading={authFormLoading}
            goToResetPasswordMode={goToResetPasswordMode}
            revertToStartStage={revertToStartStage}
          />
        ) : isRegisterMode ? (
          <RegisterMode
            onFormSubmit={onFormSubmit}
            handleChange={handleChange}
            formData={formData}
            submitState={submitState}
            fieldsValid={fieldsValid}
            authFormLoading={authFormLoading}
            revertToLoginMode={revertToLoginMode}
          />
        ) : isResetPasswordMode ? (
          <ResetPasswordMode
            revertToLoginMode={revertToLoginMode}
            formData={formData}
          />
        ) : (
          isSuccessMode && (
            <PopUpThanksForRegistration
              userName={formData.FullName}
              email={formData.Email}
              closePopup={closePopup}
            />
          )
        )}
      </div>
      {!isLoginMode && !isRegisterMode && (
        <div className="fixed bottom-0 left-0 w-screen h-[50px] bg-black opacity-[60%] flex justify-center items-center pr-[75px] md:pr-[10px] p-[10px]">
          <div className="text-10-16-0.3 lg:text-16-16-0.3 text-white text-center">
            This site is protected by reCAPTCHA and the Google
            <a
              href="https://policies.google.com/privacy"
              className="text-gold-darker"
              target="_blank"
              rel="noreferrer noopener"
            >
              {' '}
              Privacy Policy
            </a>{' '}
            and
            <a
              href="https://policies.google.com/terms"
              className="text-gold-darker"
              target="_blank"
              rel="noreferrer noopener"
            >
              {' '}
              Terms of Service
            </a>{' '}
            apply.
          </div>
        </div>
      )}
    </StyledDialog>
  );
};

export default PopUpJoinOrSignIn;
