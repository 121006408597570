import { ANY } from '../../constants/building/availableCondos';
import React from 'react';
import { SearchBoxFilters } from 'components/Search/SearchInput/types';

export type AnyValue = { min: string; max: string };

export type SetFiltersValues = React.Dispatch<
  React.SetStateAction<FiltersValues>
>;
export type OnChangeFilters = (filters: FiltersValues) => void;

export const initialAny: AnyValue = {
  min: ANY,
  max: ANY,
};

export type ListingTypeTypes = {
  preConstruction: boolean;
  newConstruction: boolean;
  forRent: boolean;
  forSale: boolean;
  foreclosures: boolean;
  shortSales: boolean;
  existingCondos: boolean;
  sold: boolean | string;
};

export type ListingTypeKeys = keyof ListingTypeTypes;

export type FilterCoordinates = {
  southWest: { lng: number; lat: number };
  northEast: { lng: number; lat: number };
};

export type FiltersValues = ListingTypeTypes & {
  searchBoxFilters: SearchBoxFilters;
  anyPrice: AnyValue;
  anyBeds: AnyValue;
  baths: string;
  parkingPlaces: string;
  sqft: AnyValue;
  yearBuild: AnyValue;
  daysOnCbb: string;
  closedAt: string;
  petFriendly: boolean;
  bitcoin: boolean;
  sellerFinancing: boolean;
  shortTermRent: boolean;
  furnished: boolean;
  unfurnished: boolean;
  intracoastalView: boolean;
  oceanView: boolean;
  skylineView: boolean;
  beachfront: boolean;
  oceanfront: boolean;
  wfAny: boolean;
  penthouse?: boolean;
  keyWords: string;
  showPendingListings: boolean;
  maxHOA: string;
  corner: boolean;
  floor: AnyValue;
  priceSqFt: AnyValue;
  unitNumberRegex: string;
  unitsInBuilding: AnyValue;
  rentalInvestments: boolean;
  lowDownPayment: boolean;
  shortTermRentTerms: string[];
  secretSearch: string;
  coordinates?: FilterCoordinates;
  sortOption?: string;
};

export type PropertyType = 'sale' | 'rent';

export type ListingsCountsType = { [index in ListingTypeKeys]: number };
