import {
  EntityWithId,
  StrapiBulkResponseData,
  StrapiResponseData,
} from 'apolloClient/types';

export const flattenStrapiBulkDataItems = <T>(
  items?: StrapiBulkResponseData<T>
): EntityWithId<T>[] => {
  return items ? items.map(flattenStrapiDataItem) : [];
};

export const flattenStrapiDataItem = <T>(
  item?: StrapiResponseData<T>
): EntityWithId<T> =>
  item ? { id: item.id || 0, ...item.attributes } : ({} as EntityWithId<T>);
