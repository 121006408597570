import React, { createContext, useContext } from 'react';
import { LOAD_HEADER_DATA } from 'apolloClient/queries/header';
import { useQuery } from '@apollo/client';
import { HeaderData } from '../Navigation';

const defaultVariables = {
  loadBuildings: false,
  loadNhds: false,
};

export const HeaderContext = createContext<{
  fetchMoreBuildings: (query: { page?: number; search?: string }) => void;
  fetchMoreNhds: (search: string) => void;
  data?: HeaderData;
}>({
  fetchMoreBuildings: () => {},
  fetchMoreNhds: () => {},
});

const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { fetchMore, data } = useQuery<
    HeaderData,
    {
      neighborhoodSearch?: string;
      buildingSearch?: string;
      buildingPage?: number;
      loadNhds: boolean;
      loadBuildings: boolean;
    }
  >(LOAD_HEADER_DATA, {
    variables: {
      buildingPage: 0,
      buildingSearch: '',
      neighborhoodSearch: '',
      loadBuildings: true,
      loadNhds: true,
    },
  });

  const fetchMoreBuildings = ({
    page,
    search,
  }: {
    page?: number;
    search?: string;
  }) => {
    fetchMore({
      variables: {
        ...defaultVariables,
        loadBuildings: true,
        buildingPage: page || 1,
        buildingSearch: search,
      },
      updateQuery(previousQueryResult, options) {
        const {
          fetchMoreResult: { buildings },
        } = options;
        return {
          ...previousQueryResult,
          buildings: {
            meta: buildings.meta,
            data: page
              ? previousQueryResult.buildings.data.concat(buildings.data)
              : buildings.data,
          },
        };
      },
    });
  };

  const fetchMoreNhds = (search: string) => {
    fetchMore({
      variables: {
        ...defaultVariables,
        loadNhds: true,
        neighborhoodSearch: search,
      },
      updateQuery(previousQueryResult, options) {
        const {
          fetchMoreResult: { neighborhoods },
        } = options;
        return {
          ...previousQueryResult,
          neighborhoods,
        };
      },
    });
  };
  return (
    <HeaderContext.Provider value={{ fetchMoreBuildings, fetchMoreNhds, data }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);

export default HeaderProvider;
