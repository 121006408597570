import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { gqlUrl } from 'lib/apiUrl';
import {
  getAuthDataFromCookies,
  getAuthDataFromSessionStorage,
} from 'src/utils/authUtils';

const httpLink = createHttpLink({
  uri: gqlUrl,
  credentials: 'include',
});

let logout: () => void = () => {};

const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error, _operation) => {
      if (error.statusCode === 401) {
        logout();
        return true;
      }
      return false;
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const { jwt } = getAuthDataFromSessionStorage().jwt
    ? getAuthDataFromSessionStorage()
    : getAuthDataFromCookies();
  return {
    headers: {
      ...headers,
      authorization: jwt ? `Bearer ${jwt}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: retryLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      context: {},
    },
  },
});

export const setLogoutHandler = (logoutHandler: () => void) => {
  logout = logoutHandler;
};
