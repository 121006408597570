export interface FormDataInterface {
  FullName: string;
  Password: string;
  Email: string;
  showPassword: boolean;
  RegistrationSource?: string;
  isInvalidPassword: boolean;
  isRegisterError: boolean;
  isNeedPwdReset: boolean;
  isNeedShowLegacyMessage: boolean;
  isCaptchaValidationFailed: boolean;
}

export enum FormMode {
  LOGIN = 'login',
  REGISTER = 'register',
  SUCCESS = 'success',
  RESET_PASSWORD = 'resetPassword',
}

export interface SubmitStateInterface {
  isTouched: boolean;
}

export interface FieldsValidInterface {
  Email: boolean;
  Password: boolean;
  FullName: boolean;
}
