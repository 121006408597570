import { gql } from '@apollo/client';

export const GET_FUB_BY_ID = gql`
  query GetFubById($id: Int!) {
    getPeopleById(id: $id) {
      id
      emails {
        value
      }
      phones {
        value
      }
      name
      assignedTo
    }
  }
`;
