import { gql } from '@apollo/client';

export const LOAD_HEADER_DATA = gql`
  query HeaderData(
    $neighborhoodSearch: String
    $buildingSearch: String
    $buildingPage: Int
    $loadNhds: Boolean!
    $loadBuildings: Boolean!
  ) {
    neighborhoods(
      pagination: { pageSize: 1000 }
      sort: ["name:ASC"]
      filters: {
        or: [
          { name: { containsi: $neighborhoodSearch } }
          { city: { name: { containsi: $neighborhoodSearch } } }
        ]
        slug: { notNull: true }
        publishedAt: { notNull: true }
      }
    ) @include(if: $loadNhds) {
      data {
        attributes {
          name
          slug
          city {
            data {
              attributes {
                name
                slug
                county {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          total
        }
      }
    }

    buildings(
      pagination: { page: $buildingPage, pageSize: 50 }
      sort: ["name:ASC"]
      filters: {
        or: [
          { name: { containsi: $buildingSearch } }
          { neighborhood: { name: { containsi: $buildingSearch } } }
        ]
        slug: { notNull: true }
        publishedAt: { notNull: true }
        neighborhood: { publishedAt: { notNull: true } }
      }
    ) @include(if: $loadBuildings) {
      data {
        attributes {
          name
          slug
          neighborhood {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          total
        }
      }
    }
  }
`;

export const GET_LAST_LISTING_UPDATED = gql`
  query LastListingUpdated {
    lastListingUpdate {
      data {
        attributes {
          lastListingUpdate
        }
      }
    }
  }
`;
