import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ApolloQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { Entity, Pagination } from 'apolloClient/types/common';
import useSnackbar from 'components/SnackBar/useSnackbar';
import {
  FavoriteListing,
  FavoriteListingsResponse,
} from 'apolloClient/types/FavoriteListing';
import { GET_FAVORITE_LISTINGS } from 'apolloClient/queries/favoriteListings';
import {
  CREATE_FAVORITE_LISTING,
  DELETE_FAVORITE_LISTING,
} from 'apolloClient/mutations/favoriteListings';
import { useAuth } from 'components/Auth/AuthProvider';
import { PropertyType } from 'components/UnitsFilters/types';

export type FavoritesVariables = {
  unit: number;
  type: PropertyType;
  alertFrequency: string;
};

type Response = { favoriteListings: FavoriteListingsResponse };

type FetchResult = ApolloQueryResult<Response>;

export type LatesAction = {
  unitId: number;
  type: PropertyType;
  favoriteId: number;
  action: 'delete' | 'create';
} | null;

export type FavoritesContext = {
  favorites: Entity<FavoriteListing>[];
  deleteFavorite: (params: {
    id: number;
    unitId: number;
    type: PropertyType;
  }) => Promise<void>;
  createFavorite: (data: FavoritesVariables) => Promise<void>;
  getFavorites: (page?: number) => void;
  loading?: boolean;
  pagination: Pagination;
  latestAction: LatesAction;
  count: number;
};

const NOOP = () => new Promise<Partial<FetchResult>>((res) => res({}));

export const FavoritesListingsContext = createContext<FavoritesContext>({
  favorites: [],
  deleteFavorite: () => new Promise<void>((res) => res()),
  createFavorite: () => new Promise<void>((res) => res()),
  getFavorites: NOOP,
  pagination: {
    total: 0,
  },
  latestAction: null,
  count: 0,
});

export const useFavorite = () => useContext(FavoritesListingsContext);

const paginationPageSize = 6;

const FavoritesListingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLogged, favoritesCount, getActualAuthData } = useAuth();

  const { showSuccess, showError } = useSnackbar();
  const [fetch, { data, updateQuery, loading }] = useLazyQuery<
    Response,
    { page: number; pageSize: number; userId: number }
  >(GET_FAVORITE_LISTINGS, {
    variables: { page: 1, pageSize: paginationPageSize, userId: 0 },
    fetchPolicy: 'network-only',
  });
  const [createFavoriteMutation] = useMutation<
    { createFavoriteListing: { data: { id: number } } },
    { data: FavoritesVariables }
  >(CREATE_FAVORITE_LISTING);
  const [deleteFavoriteMutation] = useMutation<
    { deleteFavoriteListing: null },
    { id?: number }
  >(DELETE_FAVORITE_LISTING);

  const [latestAction, setLatestAction] = useState<LatesAction>(null);
  const [count, steCount] = useState(0);

  useEffect(() => {
    if (favoritesCount) {
      steCount(favoritesCount);
    }
  }, [favoritesCount]);

  useEffect(() => {
    if (!isLogged && data) {
      updateQuery(() => ({
        favoriteListings: {
          data: [],
          meta: {
            pagination: {
              page: 1,
              pageCount: 0,
              pageSize: 0,
              total: 0,
            },
          },
        },
      }));
    }
  }, [isLogged, updateQuery, data]);

  const getFavorites = useCallback(
    (page?: number) => {
      const auth = getActualAuthData();

      if (!auth.user.id) return;
      fetch({
        variables: {
          page: page || 1,
          pageSize: paginationPageSize,
          userId: auth.user.id,
        },
      });
    },
    [fetch, getActualAuthData]
  );

  const createFavorite = async (data: FavoritesVariables) => {
    const res = await createFavoriteMutation({
      variables: {
        data,
      },
    })
      .then(({ data: resp }) => {
        if (resp?.createFavoriteListing?.data?.id) {
          setLatestAction({
            action: 'create',
            favoriteId: resp.createFavoriteListing.data.id,
            unitId: data.unit,
            type: data.type,
          });
          showSuccess({ message: 'Saved to favorites successfully' });
          steCount(count + 1);
        }
      })
      .catch((err) => {
        if (err.message === 'Already exists') {
          showError({ message: 'Unit already saved' });
        }
      });
  };

  const deleteFavorite = async ({
    id,
    type,
    unitId,
  }: {
    id: number;
    unitId: number;
    type: PropertyType;
  }) => {
    const res = await deleteFavoriteMutation({ variables: { id } }).then(
      (resp) => {
        if (resp?.data) {
          setLatestAction({
            action: 'delete',
            type,
            unitId,
            favoriteId: id,
          });
          showSuccess({ message: 'Deleted from favorites successfully' });
          steCount(count - 1);
        }
      }
    );
  };

  const pagination = data?.favoriteListings?.meta?.pagination || {};

  return (
    <FavoritesListingsContext.Provider
      value={{
        favorites: data?.favoriteListings?.data || [],
        createFavorite,
        deleteFavorite,
        getFavorites,
        loading,
        pagination,
        latestAction,
        count,
      }}
    >
      {children}
    </FavoritesListingsContext.Provider>
  );
};

export default FavoritesListingsProvider;
