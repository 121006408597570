import { IS_PROD } from 'src/constants';

export const event = ({ action, params }: { action: string; params: any }) => {
  window.gtag('event', action, params);
};

export const pageview = (
  url: string,
  params: { [key: string]: string } = {}
) => {
  // if (!IS_PROD) return;

  window.gtag('config', 'G-X7BM6WN2N9', {
    send_page_view: false,
  });

  event({
    action: 'page_view',
    params: {
      page_path: url,
      ...params,
    },
  });
};

export const blogView = (url: string, tags: string[]) => {
  pageview(url, { blog_tag: tags.join(', ') });
};
