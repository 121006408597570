import { gql } from '@apollo/client';

export const CREATE_SAVED_SEARCH = gql`
  mutation ($data: SavedSearchInput!) {
    createSavedSearch(data: $data) {
      data {
        id
      }
    }
  }
`;

export const DELETE_SAVED_SEARCH = gql`
  mutation ($id: ID) {
    deleteSavedSearch(id: $id) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_SAVED_SEARCH = gql`
  mutation ($id: ID!, $data: SavedSearchInput!) {
    updateSavedSearch(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;
