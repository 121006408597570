import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useMutation } from '@apollo/client';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import FlagLabel from 'components/FlagLabel';
import iconClose from 'public/images/close.svg';
import { removeQueryParams } from 'src/utils/utils';
import { RESET_PASSWORD } from 'apolloClient/mutations/auth';

interface PopUpNewPasswordProps {}

const initialFormData = {
  Password: '',
  RepeatPassword: '',
};

const PopUpNewPassword: React.FC<PopUpNewPasswordProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const router = useRouter();
  const [formData, setFormData] = useState(initialFormData);
  const [sendResetPwdRequest, resetPwdRequestDetails] =
    useMutation(RESET_PASSWORD);

  const close = () => {
    setIsOpen(false);
    removeQueryParams(['rpToken', 'email'], router);
  };

  useEffect(() => {
    if (router.query.rpToken && router.query.email) {
      setIsOpen(true);
      setFormData(initialFormData);
    }
  }, [router]);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSubmitted(true);

    if (
      formData.Password &&
      formData.Password === formData.RepeatPassword &&
      formData.Password.length >= 6
    ) {
      sendResetPwdRequest({
        variables: {
          code: router.query.rpToken,
          password: formData.Password,
          passwordConfirmation: formData.RepeatPassword,
        },
      })
        .then((res) => {
          if (res?.data?.errors?.length > 0) {
            setIsCodeInvalid(true);
          } else {
            setIsSuccess(true);
          }
        })
        .catch((err) => setIsCodeInvalid(true));
    }
  };

  const onPwdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      Password: e.target.value,
    });
  };

  const onRepeatPwdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      RepeatPassword: e.target.value,
    });
  };

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className="h-screen p-10 bg-white border border-gray md:h-full md:w-full">
        <div className="flex flex-row items-start justify-between mb-6">
          <div className="relative flex flex-col justify-center w-max">
            <FlagLabel name="Reset password" />
          </div>
          <button className="z-50 outline-none" onClick={close}>
            <Image src={iconClose} alt="close popup" width={15} height={15} />
          </button>
        </div>
        {!isSubmitted || !isSuccess ? (
          <div className="w-auto md:w-[420px]">
            <div className="mb-4">
              Set new password for{' '}
              <span className="font-sohneKraftig">{router.query.email}</span>
            </div>
            <form
              className="flex flex-col justify-center w-auto mt-8 text-16-26-0.3 align-center md:w-[420px]"
              onSubmit={onFormSubmit}
            >
              <input
                className="text-16-26-0.3 border form-input"
                type="password"
                placeholder="New password"
                name="Password"
                autoComplete="new-password"
                value={formData.Password}
                onChange={onPwdChange}
              />
              <input
                className="text-16-26-0.3 border form-input mt-5 mb-4"
                type="password"
                placeholder="Re-enter new password"
                name="Password"
                autoComplete="repeat-password"
                value={formData.RepeatPassword}
                onChange={onRepeatPwdChange}
              />
              {isSubmitted &&
                (!formData.Password || formData.Password.length < 6 ? (
                  <span className="field-validation-error">
                    <span className="pl-3 text-red">
                      Password is required and should be at least 6 characters
                      length
                    </span>
                  </span>
                ) : formData.Password !== formData.RepeatPassword ? (
                  <span className="field-validation-error">
                    <span className="pl-3 text-red">
                      Repeat password should be the same
                    </span>
                  </span>
                ) : (
                  isCodeInvalid && (
                    <span className="field-validation-error">
                      <span className="pl-3 text-red">
                        Password reset is failed. The key is expired.
                      </span>
                    </span>
                  )
                ))}
              <div className="flex justify-between mt-6 text-12-18-0.3 md:text-16-26-0.3">
                <button
                  className="w-32 h-10 pt-1 uppercase bg-white border border-gold hover:bg-gold hover:border-gold"
                  onClick={close}
                >
                  Cancel
                </button>
                <button
                  className="w-32 h-10 pt-1 uppercase bg-gold hover:bg-gold-hover"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            Password for{' '}
            <span className="font-sohneKraftig">{router.query.email}</span> was
            successfully changed. Now you can close this dialog and use your new
            password for log in.
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default PopUpNewPassword;
