import React, { useState } from 'react';
import classnames from 'classnames';

import {
  FormDataInterface,
  SubmitStateInterface,
  FieldsValidInterface,
} from './interfaces';

interface RegisterModeProps {
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: FormDataInterface;
  submitState: SubmitStateInterface;
  fieldsValid: FieldsValidInterface;
  authFormLoading: boolean;
  revertToLoginMode: () => void;
}

const RegisterMode: React.FC<RegisterModeProps> = ({
  onFormSubmit,
  formData,
  handleChange,
  submitState,
  fieldsValid,
  authFormLoading,
  revertToLoginMode,
}) => {
  const [dataType, setDataType] = useState(false);

  return (
    <form
      data-type={dataType && 'registration_successful'}
      className="flex flex-col justify-center w-auto mt-8 text-16-26-0.3 align-center md:w-[420px]"
      onSubmit={onFormSubmit}
    >
      <input
        className="text-16-26-0.3 border form-input"
        type="text"
        placeholder="First and Last Name"
        name="FullName"
        autoComplete="new-password"
        value={formData.FullName}
        onChange={handleChange}
      />
      {submitState.isTouched && !fieldsValid.FullName && (
        <span className="field-validation-error">
          <span className="pl-3 text-red">Full Name is invalid</span>
        </span>
      )}
      <input
        className="mt-4 text-16-26-0.3 border form-input"
        type="text"
        placeholder="Phone # (used as password)"
        name="Password"
        autoComplete="new-password"
        value={formData.Password}
        onChange={handleChange}
      />
      {submitState.isTouched && !fieldsValid.Password && (
        <span className="field-validation-error">
          <span className="pl-3 text-red">Password is invalid</span>
        </span>
      )}
      {submitState.isTouched && formData.isRegisterError && (
        <span className="field-validation-error">
          <span className="pl-3 text-red">
            Server error on registration. Try using other password and/or name.
          </span>
        </span>
      )}
      {submitState.isTouched && formData.isCaptchaValidationFailed && (
        <span className="field-validation-error">
          <span className="pl-3 text-red">
            Recaptcha is not passed. Please try again later
          </span>
        </span>
      )}
      <div className="flex justify-between mt-6 text-12-18-0.3 md:text-16-26-0.3">
        <button
          className="w-32 h-10 uppercase bg-white border border-gold hover:bg-gold hover:border-gold"
          onClick={revertToLoginMode}
          type="button"
        >
          Back
        </button>
        <button
          id="Sign_Up_Send"
          onClick={() => setDataType(true)}
          className={classnames('w-32 h-10 uppercase bg-gold border', {
            'border-gold bg-gold hover:bg-gold-hover hover:border-gold-hover':
              authFormLoading,
            'border-gold hover:bg-gold-hover hover:border-gold-hover':
              !authFormLoading,
          })}
          type="submit"
        >
          Sign Up
        </button>
      </div>
    </form>
  );
};

export default RegisterMode;
