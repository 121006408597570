import { gql } from '@apollo/client';

export const SEND_FUB_PAGE_VIEW_EVENT = gql`
  mutation SendFubPageViewEvent($input: FollowUpBossCreateEventEntity!) {
    createFollowUpBossEvent(input: $input) {
      id
    }
  }
`;

export const SEND_FUB_EVENT = gql`
  mutation SendFubPageViewEvent($input: FollowUpBossCreateEventEntity!) {
    createFollowUpBossEvent(input: $input) {
      id
    }
  }
`;

export const UPDATE_FUB_USER = gql`
  mutation UpdateFollowUpBossUser(
    $fubId: Int
    $email: String
    $phone: String
    $name: String
    $browserUUID: String
  ) {
    updateFollowUpBossUser(
      fubId: $fubId
      email: $email
      phone: $phone
      name: $name
      browserUUID: $browserUUID
    ) {
      id
      name
      emails {
        value
      }
      phones {
        value
      }
    }
  }
`;

export const SEND_CRISP_MSGS_AS_NOTES_TO_FUB = gql`
  mutation SendCrispMessagesAsNotes($fubId: Int!, $messages: [JSON]!) {
    sendCrispMessagesAsNotes(leadId: $fubId, messages: $messages)
  }
`;

export const ADD_FUB_TAG = gql`
  mutation ($tag: String!, $fubId: String!) {
    addTagToFollowUpBossUser(tag: $tag, fubId: $fubId)
  }
`;
