import React from 'react';
import CbbDropdown from '../../../CbbDropdown';

import { SetFiltersValues } from '../../types';

interface Props {
  initialValue: string;
  setValues: SetFiltersValues;
}

export const initialBathsInputValue = '0';

const Baths: React.FC<Props> = ({ initialValue, setValues }) => {
  return (
    <div className="w-[152px] md:w-[137px]">
      <label htmlFor="" className="md:text-14-26-0.3">
        Baths
      </label>
      <div className="flex align-center leading-[21px]">
        <CbbDropdown
          showPlus
          onChange={(value) =>
            setValues((values) => ({
              ...values,
              baths: value.replace(/'+'/g, ''),
            }))
          }
          rootClassName="w-[152px] md:w-[137px] pt-[8px] pb-[9px] pl-[16px] h-[44px] md:w-36 text-black"
          selectClassName="w-[152px] md:w-[137px] h-[44px] -left-0.1"
          nameSelected="0"
          initialSelectedOption={initialValue}
          options={[
            initialBathsInputValue,
            '1',
            '1.5',
            '2',
            '2.5',
            '3',
            '4',
            '5',
            '6',
          ]}
        />
      </div>
    </div>
  );
};

export default Baths;
