import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { AuthenticatedUserInfo } from '../../apolloClient/types';
import {
  AUTH_DATA,
  LOGIN_AS_USER,
  initialAuthData,
} from '../constants/authConstants';

import {
  getCookie,
  getLoggedAsUserSessionStorage,
  setCookie,
  setLoggedAsUserSessionStorage,
} from './utils';

export function updateAuthDataInCookies(authData: AuthenticatedUserInfo) {
  setCookie(AUTH_DATA, JSON.stringify(authData), 30);
}

export function isLoginAsUser() {
  let res;
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage.getItem(LOGIN_AS_USER) !== null
  ) {
    res = window.sessionStorage.getItem(LOGIN_AS_USER)!;
  }
  return res === 'true';
}

export function updateLoginAsUserInCookies(isLoginAsUser: boolean) {
  setCookie(LOGIN_AS_USER, isLoginAsUser.toString(), 30);

  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(LOGIN_AS_USER, isLoginAsUser.toString());
  }
}

export function updateAuthDataInSessionStorage(
  authData: AuthenticatedUserInfo
) {
  setLoggedAsUserSessionStorage(AUTH_DATA, JSON.stringify(authData));
}

export function getAuthDataFromCookies(
  cookies?: string
): AuthenticatedUserInfo {
  try {
    let dataFromCookies = getCookie(AUTH_DATA, cookies);
    let data: AuthenticatedUserInfo = Boolean(dataFromCookies)
      ? JSON.parse(dataFromCookies)
      : { ...initialAuthData, isMock: false };
    return data;
  } catch (error) {
    console.error('Error parsing JSON getAuthDataFromCookies', error);

    return initialAuthData;
  }
}

export function getAuthDataFromSessionStorage(): AuthenticatedUserInfo {
  try {
    let dataFromSessionStorage = getLoggedAsUserSessionStorage();
    let data: AuthenticatedUserInfo = Boolean(dataFromSessionStorage)
      ? JSON.parse(dataFromSessionStorage)
      : { ...initialAuthData, isMock: false };
    return data;
  } catch (error) {
    console.error('Error parsing JSON getAuthDataFromSessionStorage', error);

    return initialAuthData;
  }
}

export function getJWTFromCookiesInServerSideProps(
  cookies: NextApiRequestCookies
): string {
  try {
    const authData = cookies[AUTH_DATA];
    const jwt = authData ? JSON.parse(authData).jwt : '';
    return jwt;
  } catch (error) {
    console.error(
      'Error parsing JSON getJWTFromCookiesInServerSideProps',
      error
    );

    return '';
  }
}
