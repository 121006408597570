export type StatusCode =
  | 'A'
  | 'AC'
  | 'C'
  | 'CS'
  | 'PS'
  | 'R'
  | 'T'
  | 'W'
  | 'X'
  | 'Q';

export enum StatusCodeEnum {
  ACTIVE = 'A',
  ACTIVE_WITH_CONTRACT = 'AC',
  CANCELLED = 'C',
  CLOSED_SALE = 'CS',
  PENDING_SALE = 'PS',
  RENTED = 'R',
  TEMP_OFF_MARKET = 'T',
  WITHDRAWN = 'W',
  EXPIRED = 'X',
  TERMINATED = 'Q',
}

export const StatusCodeGroup = {
  OFF_MARKET: [
    StatusCodeEnum.PENDING_SALE,
    StatusCodeEnum.CANCELLED,
    StatusCodeEnum.EXPIRED,
    StatusCodeEnum.TEMP_OFF_MARKET,
    StatusCodeEnum.WITHDRAWN,
    StatusCodeEnum.TERMINATED,
  ],
  ACTIVE: [StatusCodeEnum.ACTIVE, StatusCodeEnum.ACTIVE_WITH_CONTRACT],
};

export const offMarketStatusCodes: string[] = Object.values(
  StatusCodeGroup.OFF_MARKET
);

type TranslateType = {
  [key in StatusCode]: string;
};

export const translate: TranslateType = {
  A: 'Active',
  AC: 'Active with contract',
  CS: 'Sold',
  R: 'Rented',
  C: 'Cancelled',
  PS: 'Under contract',
  T: 'Temp off market',
  Q: 'Terminated',
  W: 'Withdrawn',
  X: 'Expired',
};
