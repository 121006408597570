import { initialBathsInputValue } from './components/moreFiltersFields/Baths';
import { ANY } from '../../constants/building/availableCondos';
import { FiltersValues, initialAny } from './types';
import { initialSearchBoxFilters } from 'components/Search/SearchInput/types';

export const INTERCOASTAL_VIEW = 'intracoastalView';
export const PET_FRIENDLY = 'petFriendly';
export const SKYLINE_VIEW = 'skylineView';
export const FURNISHED = 'furnished';
export const UNFURNISHED = 'unfurnished';
export const OCEAN_VIEW = 'oceanView';
export const BEACH_FRONT = 'beachfront';
export const OCEAN_FRONT = 'oceanfront';
export const ANY_WATERFRONT = 'wfAny';
export const PENTHOUSE = 'penthouse';

export const defaultFilterValuesWithoutListingTypes = {
  anyPrice: initialAny,
  anyBeds: initialAny,
  baths: initialBathsInputValue,
  parkingPlaces: ANY,
  sqft: initialAny,
  yearBuild: initialAny,
  daysOnCbb: ANY,
  closedAt: ANY,
  [PET_FRIENDLY]: false,
  [FURNISHED]: false,
  [UNFURNISHED]: false,
  [INTERCOASTAL_VIEW]: false,
  [OCEAN_VIEW]: false,
  [SKYLINE_VIEW]: false,
  [OCEAN_FRONT]: false,
  [BEACH_FRONT]: false,
  [PENTHOUSE]: false,
  [ANY_WATERFRONT]: false,
  keyWords: '',
  showPendingListings: false,
  maxHOA: ANY,
  bitcoin: false,
  sellerFinancing: false,
  shortTermRent: false,
  corner: false,
  floor: initialAny,
  priceSqFt: initialAny,
  unitsInBuilding: initialAny,
  unitNumberRegex: '',
  rentalInvestments: false,
  lowDownPayment: false,
  shortTermRentTerms: [],
  secretSearch: '',
};

export const defaultFilterValues: FiltersValues = {
  ...defaultFilterValuesWithoutListingTypes,
  preConstruction: false,
  newConstruction: false,
  foreclosures: false,
  shortSales: false,
  existingCondos: false,
  forRent: false,
  forSale: false,
  sold: false,
  searchBoxFilters: initialSearchBoxFilters,
};

export const sortOptions = {
  RECOMMENDED: 'Recommended',
  PRICE_HIGHEST_FIRST: 'Price Highest First',
  PRICE_LOWEST_FIRST: 'Price Lowest First',
  SQUARE_FEET: 'Square Feet',
  BEDROOMS: 'Bedrooms',
  RECENT_CHANGES: 'Recent Changes',
};

export const defaultSortOption = sortOptions.RECOMMENDED;

export const SEARCH_SALE_FILTERS = {
  preConstruction: true,
  newConstruction: true,
  foreclosures: true,
  shortSales: true,
  existingCondos: true,
  forSale: true,
  sortOption: defaultSortOption,
};

export const SEARCH_RENT_FILTERS = {
  forRent: true,
  sortOption: defaultSortOption,
};
