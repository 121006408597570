import { NextRouter } from 'next/router';

import { NO_DATA_PLACEHOLDER } from '../../constants/labels';
import { Address } from '../../apolloClient/types';
import { ATT } from '../constants/constants';
import { translate } from 'constants/listings/statusCodes';

export const emailRegex =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const checkNan = (toCheck: any) => !Number.isNaN(Number(toCheck));

export const centenaryNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
}).format;

export const numberFormatter = new Intl.NumberFormat('en-US', {}).format;

export const sqftFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

export const sqftFormatterM2 = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format;

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

export const priceFormatterWithCent = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const decimalNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}).format;

export const decimalNumberFormatterWithoutZero = new Intl.NumberFormat(
  'en-US',
  {
    maximumFractionDigits: 1,
  }
).format;

export const percentFormatterFractionZero = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
}).format;

export const abbreviatedPriceFormatterWithPrecision = (precision: number = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
    notation: 'compact',
    compactDisplay: 'short',
  }).format;

export const abbreviatedPriceFormatter =
  abbreviatedPriceFormatterWithPrecision(0);

export const abbreviatedPriceFormatterGain =
  abbreviatedPriceFormatterWithPrecision(1);

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
}).format;

export const isNotEmpty = (value: string | undefined) => {
  return value !== undefined && value !== '';
};

export const validateEmail = (value: string | undefined) => {
  return isNotEmpty(value) && emailRegex.test(value as string);
};

export const removeQueryParams = (params: string[], router: NextRouter) => {
  const { pathname, query } = router;
  const paramsObj = new URLSearchParams(query as {});
  for (const param of params) {
    paramsObj.delete(param);
  }
  router.replace({ pathname, query: paramsObj.toString() }, undefined, {
    shallow: true,
  });
};

export const getAgentIdFromLocalStorage = (): string | null => {
  return localStorage.getItem('agent');
};

export const getLeadIdFromLocalStorage = (): string | null => {
  return localStorage.getItem('leadId');
};

export const getbrowserUUIDFromLocalStorage = (): string | null => {
  return localStorage.getItem('browserUUID');
};

export const saveLeadIdToLocalStorage = (leadId: string) => {
  localStorage.setItem('leadId', leadId);
};

export const saveAgentDataToLocalStorage = (agentsData: string) => {
  localStorage.setItem('agentsData', agentsData);
};

export const getAgentDataFromLocalStorage = (): string | null => {
  return localStorage.getItem('agentsData');
};

export const saveAgentIdToLocalStorage = (id: string) => {
  const idFromLocalStorage = getAgentIdFromLocalStorage();
  (idFromLocalStorage === null || idFromLocalStorage !== id) &&
    localStorage.setItem('agent', id);
};

export const getNumberFromString = (string: string) => {
  return Number(string.replace(/[^0-9.]/g, ''));
};

export const normilizeUnitDescription = (field: string) => {
  return field?.trim().split(',').join(', ');
};

export const isEqualValues = (valueA: any, valueB: any): boolean => {
  return JSON.stringify(valueA) === JSON.stringify(valueB);
};

export function setCookie(name: string, value: string, expDays: number) {
  let expires = '';
  let domain = '';
  if (expDays) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if (location.hostname.includes('condoblackbook.com')) {
    domain = '; Domain=condoblackbook.com';
  }
  document.cookie = name + '=' + (value || '') + expires + domain + '; path=/';
}

export function getCookie(name: string, cookies?: string): string {
  let res;
  if (typeof window !== 'undefined' || cookies) {
    const normalizedName = name + '=';
    const normalizeCookies = cookies || decodeURIComponent(document.cookie);
    const cookiesArr = normalizeCookies.split('; ');
    cookiesArr.forEach((val) => {
      if (val.indexOf(normalizedName) === 0)
        res = val.substring(normalizedName.length);
    });
  }
  return res || '';
}

export function setLoggedAsUserSessionStorage(name: string, value: string) {
  window.sessionStorage.setItem(name, value);
  window.sessionStorage.setItem('loggedAsUser', 'true');
}

export function getLoggedAsUserSessionStorage() {
  let res;
  if (
    typeof window !== 'undefined' &&
    window.sessionStorage.getItem('authData') !== null
  ) {
    res = window.sessionStorage.getItem('authData')!;
  }
  return res || '';
}

export function getAssociationFee(values: {
  hoaFees: number;
  maintenanceFee: number;
  associationFeePaidPer: string;
  sqft: number;
}): string {
  const { hoaFees, maintenanceFee, sqft } = values;

  if (!sqft || !(hoaFees || maintenanceFee)) return NO_DATA_PLACEHOLDER;
  return `${priceFormatter((hoaFees || maintenanceFee) / sqft)}/month`;
}

export function getNormalizedAddress(data: Address & { city?: string }) {
  const { streetNumber, streetName, streetType, zipCode, city } = data;
  let baseAddress = `${streetNumber} ${streetName} ${streetType}`;
  if (city) baseAddress += `, ${city}`;
  baseAddress += `, ${zipCode}`;
  return baseAddress;
}

export function formatPhoneNumber(phoneNumber: string): {
  areaCode: string;
  phoneNumber: string;
} {
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
  const areaCode = cleanedPhoneNumber.slice(0, 3);
  const centralOfficeCode = cleanedPhoneNumber.slice(3, 6);
  const lineNumber = cleanedPhoneNumber.slice(6);
  return { areaCode, phoneNumber: `${centralOfficeCode}-${lineNumber}` };
}

export function switchWasOnceLogged() {
  !wasOnceLogged() && localStorage.setItem('userWasLogged', 'true');
}

export function wasOnceLogged(): boolean {
  return Boolean(localStorage.getItem('userWasLogged'));
}

export function getATTFromLocalStorage(): string | null {
  return localStorage.getItem(ATT);
}

interface Data {
  previousStatusCode: string;
  statusCode: string;
  propertyTypeCode: string;
}

interface Listing {
  propertyTypeCode: string;
}

type StatusCode = keyof typeof translate;

export const getStatusText = (data: Data, listing: Listing): string => {
  const { previousStatusCode, statusCode } = data;
  const { propertyTypeCode } = listing;

  if (statusCode === previousStatusCode) {
    return 'Price change';
  }

  const statusMapping: Record<string, string> = {
    CS: 'Sold',
    R: 'Rented',
    AC: 'Active With Contract',
    B: 'Under Contract/Backup',
  };

  if (statusMapping[statusCode]) {
    return statusMapping[statusCode];
  }

  if (
    previousStatusCode !== 'AC' &&
    previousStatusCode !== 'A' &&
    (statusCode === 'AC' || statusCode === 'A')
  ) {
    return propertyTypeCode === 'R' ? 'Listed for rent' : 'Listed for sale';
  }

  if (
    previousStatusCode !== 'C' &&
    previousStatusCode !== 'X' &&
    previousStatusCode !== 'PS' &&
    previousStatusCode !== 'T' &&
    previousStatusCode !== 'W' &&
    previousStatusCode !== 'Q' &&
    statusCode === 'PS'
  ) {
    return 'Under contract';
  }

  if (
    previousStatusCode !== 'C' &&
    previousStatusCode !== 'X' &&
    previousStatusCode !== 'PS' &&
    previousStatusCode !== 'T' &&
    previousStatusCode !== 'W' &&
    previousStatusCode !== 'Q' &&
    statusCode !== 'AC' &&
    statusCode !== 'A' &&
    statusCode !== 'CS' &&
    statusCode !== 'R'
  ) {
    return 'Listing removed';
  }

  if (previousStatusCode === 'X' && statusCode === 'Q') {
    return 'Status change Expired -> Terminated';
  }

  return `Status change "${translate[previousStatusCode as StatusCode]}" -> "${
    translate[statusCode as StatusCode]
  }"`;
};
