import { pick } from 'lodash';

import { client } from 'apolloClient/client';
import {
  GET_BUILDING_FILTER,
  GET_NEIGHBORHOOD_FILTER,
} from 'apolloClient/queries/searchPage';
import { NeighborhoodResponse } from 'apolloClient/types/Neighborhood';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import { RootField } from '../types';
import { BuildingResponse } from 'apolloClient/types/Building';

export const convertNeighborhood = (resp: NeighborhoodResponse) => {
  const neigborood = flattenStrapiDataItem(resp?.data);
  const ROOT_FIELD: RootField = 'city';
  return {
    rootField: ROOT_FIELD,
    value: {
      ...flattenStrapiDataItem(neigborood[ROOT_FIELD]?.data),
      neighborhood: pick(neigborood, ['id', 'name', 'slug']),
    },
  };
};

export const getNeighborhood = async (id: number) => {
  const { data } = await client.query<
    { neighborhood: NeighborhoodResponse },
    { id: number }
  >({ query: GET_NEIGHBORHOOD_FILTER, variables: { id } });
  return data.neighborhood;
};

export const convertBuildingResponse = (buildingResponse: BuildingResponse) => {
  const building = flattenStrapiDataItem(buildingResponse?.data);
  const neigborood = flattenStrapiDataItem(building.neighborhood.data);
  const ROOT_FIELD: RootField = 'city';
  return {
    rootField: ROOT_FIELD,
    value: {
      ...flattenStrapiDataItem(neigborood[ROOT_FIELD]?.data),
      neighborhood: {
        ...pick(neigborood, ['id', 'name', 'slug']),
        building: pick(building, [
          'id',
          'name',
          'slug',
          'isPreconstruction',
          'primaryAddress',
        ]),
      },
    },
  };
};

export const getBuilding = async (id: number) => {
  const { data } = await client.query<
    { building: BuildingResponse },
    { id: number }
  >({ query: GET_BUILDING_FILTER, variables: { id } });
  return data.building;
};
