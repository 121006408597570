import { gql } from '@apollo/client';

export const UPDATE_ME = gql`
  mutation ($data: UsersPermissionsUserInput!, $id: ID!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          email
          confirmed
          blocked
          isAdmin
          fullName
          phoneNumber
          favoritesMailNotificationFrequency
          favoritesPhoneNotificationFrequency
          userType
          country
          city
          address1
          address2
          zipcode
        }
      }
    }
  }
`;

export const DELETE_SELF = gql`
  mutation {
    deleteUsersPermissionsUserSelf
  }
`;
