import Link from 'next/link';
import React from 'react';

import {
  defaultPhoneNumberWithCode,
  defaultEmail,
} from '../../src/constants/constants';
import classnames from 'classnames';

type Props = {
  isHomePage?: boolean;
};

const Footer: React.FC<Props> = ({ isHomePage }) => {
  const eventClickPhone = () => {
    window.gtag('event', 'click_phone', {
      event_category: 'click_phone',
      event_label: 'Success',
      value: 1,
    });
  };
  return (
    <footer className="text-12-18-0.3 cursor-auto bg-white-light font-sohneBuch">
      <div className="pb-8 bg-beige">
        <div
          className={classnames({
            'container-xl !pl-[20px] md:!pl-[46px] lg:!pl-[51px]': isHomePage,
            container: !isHomePage,
          })}
        >
          <div className="flex flex-col justify-between md:flex-row lg:pt-[36px]">
            <div className="flex flex-col w-full md:w-2/3 lg:flex-row lg:justify-between">
              <div className="flex">
                <a
                  href="/"
                  className={classnames(
                    `
                      h-[11px] md:h-[13px] lg:h-[14px]
                      w-[174px] md:w-[206px] lg:w-[230px]
                      pt-[33px] md:pt-[49px] lg:pt-[21px]
                      pb-[51px] md:pb-[56px]
                      ml-[12px] md:ml-[0px] 
                      lg:py-1
                    `,
                    {
                      'lg:ml-[-1px]': isHomePage,
                      'lg:ml-[5px]': !isHomePage,
                    }
                  )}
                >
                  <img src="/images/logo.svg" alt="logo" className="w-58" />
                </a>
              </div>
              <div className="flex flex-col justify-between h-full md:w-4/5 lg:w-2/5 text-14-21-0.3 md:text-12-18-0.3">
                <div
                  className={classnames(
                    `
                    pl-[11px] md:pl-[0px] lg:pl-[23px]
                    pt-1
                    pb-4 md:pb-[0px]
                    pr-4 md:pr-[0px]
                    lg:pt-[23px] lg:pr-2 xl:w-[305px]
                  `,
                    {
                      'lg:pl-[38px]': isHomePage,
                      'lg:pl-[23px]': !isHomePage,
                    }
                  )}
                >
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://goo.gl/maps/oVtjA42Z4nUeXEmb9"
                    className="uppercase"
                  >
                    LLC DBA Blackbook Properties, 1900 N bayshore DR #1A, Miami,
                    FL 33132
                  </a>
                </div>
                <div className="lg:mt-[-31px]">
                  <div
                    className={classnames(
                      'pt-[1px] md:pt-[11px] pl-[16px] md:pl-[2px] lg:pt-0 lg:px-2 ',
                      {
                        'lg:pl-[41px]': isHomePage,
                        'lg:pl-[26px]': !isHomePage,
                      }
                    )}
                  >
                    <a
                      onClick={eventClickPhone}
                      href={`tel:${defaultPhoneNumberWithCode}`}
                    >
                      {defaultPhoneNumberWithCode}
                    </a>
                  </div>
                  <div
                    className={classnames(
                      'pb-2 pl-[17px] md:pl-[5px] lg:pl-[28px]',
                      {
                        'lg:pl-[43px]': isHomePage,
                        'lg:pl-[28px]': !isHomePage,
                      }
                    )}
                  >
                    <a href={`mailto:${defaultEmail}`}>{defaultEmail}</a>
                  </div>
                </div>
                <div className="pt-[21px] pl-[11px] md:py-2 md:px-2 md:hidden">
                  Copyright 2007-2020 (C) Condo Black Book. All Rights Reserved
                </div>
                <div
                  className={classnames(
                    `
                    flex justify-between lg:mt-[-35px]
                    pt-[41px] md:pt-[16px] md:mt-[-8px] lg:pt-[0px] 
                    pl-[11px] md:pl-[7px] 
                    pr-6 
                    md:w-full lg:w-[225px] w-2/3 
                  `,
                    {
                      'lg:pl-[46px] lg:pr-0': isHomePage,
                      'lg:pl-[30px] ': !isHomePage,
                    }
                  )}
                >
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://www.youtube.com/c/BlackbookPropertiesMiamiRealEstateLifestyle"
                  >
                    <img
                      src="/images/youtube.svg"
                      alt="youtube"
                      className="hover:opacity-60"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://www.instagram.com/blackbookproperties/"
                  >
                    <img
                      src="/images/instagram.svg"
                      alt="instagram"
                      className="hover:opacity-60"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://www.linkedin.com/company/1882124"
                  >
                    <img
                      src="/images/linkedin.svg"
                      alt="linkedin"
                      className="w-6 h-6 hover:opacity-60"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://www.tiktok.com/@blackbookproperties"
                  >
                    <img
                      src="/images/tiktok.svg"
                      alt="tiktok"
                      className="w-6 h-6 hover:opacity-60"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://www.facebook.com/BlackbookProperties/"
                  >
                    <img
                      src="/images/facebook.svg"
                      alt="facebook"
                      className="hover:opacity-60"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://twitter.com/blackbookmiami"
                  >
                    <img
                      src="/images/twitter.svg"
                      alt="twitter"
                      className="w-6 h-6 hover:opacity-60"
                    />
                  </a>
                </div>
                <div className="ml-[11px] pt-[36px] py-10 md:hidden">
                  <div className="w-[314px] h-[1px] bg-black" />
                </div>
              </div>
            </div>
            <div
              className={classnames(
                'flex flex-col w-full lg:w-1/3 md:pt-28 md:mt-[-7px] lg:pt-[5px] lg:pl-[37px]',
                {
                  'lg:pl-[9px]': isHomePage,
                  'lg:pl-[37px]': !isHomePage,
                }
              )}
            >
              <div
                className={`
                  flex flex-col w-auto 
                  text-14-25-0.3 md:text-12-18-0.3 uppercase
                  md:pt-[4px] lg:pt-[25px] 
                  pl-[11px] md:pl-[3px] 
                  md:ml-20 lg:ml-32
                  md:mb-[28px] 
                `}
              >
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://realestatevaluesmiami.com/"
                  className="hover:text-gold-hover"
                >
                  Your home's value
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="search?preConstruction=true&forSale=true"
                  className="hover:text-gold-hover"
                >
                  Pre-construction condo
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/blog/miami-condo-association-hoa-contact-page"
                  className="hover:text-gold-hover"
                >
                  Association Page
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/blog"
                  className="hover:text-gold-hover"
                >
                  Blog
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/terms-of-use"
                  className="hover:text-gold-hover"
                >
                  Term of use
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/sitemap"
                  className="hover:text-gold-hover"
                >
                  Site map
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/privacy-policy"
                  className="hover:text-gold-hover"
                >
                  Privacy policy
                </a>
              </div>
              <div
                className={`
                  hidden md:block
                  xl:w-[305px]
                  px-4 py-2
                  md:pl-[3px] xl:pr-[54px]
                  md:pb-[0px] md:pt-[20px]
                  ml-20 xl:ml-32
                  md:mb-[-4px]
                `}
              >
                Copyright 2007-2020 (C) Condo Black Book. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
