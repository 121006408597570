import {
  NeighborhoodNameToBuildingMap,
  CityNameToNeighborhoodMap,
  Neighborhood,
  Building,
} from 'apolloClient/types';

import { flattenStrapiDataItem } from './flattenStrapiBulkDataItems';

export const groupByNeighborhood = (
  buildings: Building[]
): NeighborhoodNameToBuildingMap => {
  const result: NeighborhoodNameToBuildingMap = {};

  buildings.reduce(
    (accumulate: NeighborhoodNameToBuildingMap, building: Building) => {
      const name: string =
        flattenStrapiDataItem(building.neighborhood?.data)?.name || '';
      if (!accumulate[name]) {
        accumulate[name] = [];
      }

      accumulate[name].push(building);

      return accumulate;
    },
    result
  );
  return result;
};

export const groupByCity = (
  neighborhoods: Neighborhood[]
): CityNameToNeighborhoodMap => {
  const result: CityNameToNeighborhoodMap = {};

  neighborhoods.reduce(
    (accumulate: CityNameToNeighborhoodMap, neighborhood: Neighborhood) => {
      const name: string =
        flattenStrapiDataItem(neighborhood.city?.data).name || '';
      if (!accumulate[name]) {
        accumulate[name] = [];
      }

      accumulate[name].push(neighborhood);

      return accumulate;
    },
    result
  );

  return result;
};
