import { gql } from '@apollo/client';

export const CREATE_ACTIVITY = gql`
  mutation ($data: ActivityInput!) {
    createActivity(data: $data) {
      data {
        id
        attributes {
          leadId
          agentId
        }
      }
    }
  }
`;

export const MOVE_ACTIVITIES = gql`
  mutation ($email: String!, $browserUUID: String!, $isRegister: Boolean) {
    moveActivitiesToUser(
      email: $email
      browserUUID: $browserUUID
      isRegister: $isRegister
    )
  }
`;
