import { Alert, Snackbar, AlertProps } from '@mui/material';
import React, { useState } from 'react';
import SnackbarContext, {
  AlertHandler,
  SpecifficAlertIntentHandler,
} from './SnackbarContext';

type SnackbarProviderProps = {
  children: React.ReactNode;
};

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [state, setState] = useState<{ props?: AlertProps; message: string }>({
    message: '',
    props: {},
  });
  const [isopen, setOpen] = useState<boolean>(false);
  const showSnackbar: AlertHandler = (params) => {
    setTimeout(() => {
      setState(params);
      setOpen(true);
    });
  };

  const showError: SpecifficAlertIntentHandler = (params) => {
    showSnackbar({ ...params, props: { ...params.props, severity: 'error' } });
  };

  const showSuccess: SpecifficAlertIntentHandler = (params) => {
    showSnackbar({
      ...params,
      props: { ...params.props, severity: 'success' },
    });
  };

  const showWarning: SpecifficAlertIntentHandler = (params) => {
    showSnackbar({
      ...params,
      props: { ...params.props, severity: 'warning' },
    });
  };

  const showInfo: SpecifficAlertIntentHandler = (params) => {
    showSnackbar({ ...params, props: { ...params.props, severity: 'info' } });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ showSnackbar, showError, showInfo, showSuccess, showWarning }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
        autoHideDuration={3000}
        open={isopen}
      >
        <Alert {...(state.props || {})}>{state.message}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
