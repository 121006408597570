import { gql } from '@apollo/client';

export const LOAD_AGENTS_FOR_EXPERTS_SECTION = gql`
  query LoadAgentForExpertsSection {
    agents(filters: { isFeatured: { eq: true } }) {
      data {
        attributes {
          agentId {
            name
            email
            picture {
              original
            }
          }
          nickname
          job
          company
          description
        }
      }
    }
  }
`;

export const LOAD_AGENTS = gql`
  query LoadAgents($agent: String) {
    defaultAgent {
      data {
        attributes {
          common {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          higher1Million {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          higher1MillionAuthenticated {
            id
            name
            phone
            email
            picture {
              original
            }
          }
        }
      }
    }
    agents(filters: { nickname: { eq: $agent } }) {
      data {
        id
        attributes {
          agentId {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          nickname
          job
          company
          description
        }
      }
    }
  }
`;

export const LOAD_ALL_AGENTS = gql`
  query LoadAgents {
    defaultAgent {
      data {
        attributes {
          common {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          higher1Million {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          higher1MillionAuthenticated {
            id
            name
            phone
            email
            picture {
              original
            }
          }
        }
      }
    }
    agents {
      data {
        id
        attributes {
          agentId {
            id
            name
            phone
            email
            picture {
              original
            }
          }
          nickname
          job
          company
          description
        }
      }
    }
  }
`;

export const LOAD_USER_AGENTS = gql`
  query LoadUserAgents(
    $leadId: String
    $agentNick: String
    $listingPrice: Float
    $isLogged: Boolean
    $isForStorage: Boolean
  ) {
    getCustomUserAgents(
      leadId: $leadId
      agentNick: $agentNick
      listingPrice: $listingPrice
      isLogged: $isLogged
      isForStorage: $isForStorage
    ) {
      commonAgent
      secondAgent
      higherMillion
      isAssignedAgentExists
    }
  }
`;

export const LOAD_COMMON_AGENT = gql`
  query LoadDefaultAgents {
    defaultAgent {
      data {
        attributes {
          common {
            id
            name
            phone
            email
            picture {
              original
            }
          }
        }
      }
    }
  }
`;

export const LOAD_ABOUT_US_AGENTS = gql`
  query LoadAgentForExpertsSection {
    agents(filters: { showOnAboutUs: { eq: true } }) {
      data {
        attributes {
          agentId {
            name
            email
            picture {
              original
            }
          }
          nickname
          job
          company
          description
          languages
          memberships
          mediaLinks {
            url
            icon {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
