import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';

import { FORGOT_PASSWORD } from 'apolloClient/mutations/auth';

import { FormDataInterface } from './interfaces';

interface IResetPasswordProps {
  revertToLoginMode: () => void;
  formData: FormDataInterface;
}

enum EMAIL_STATUS {
  None,
  Sent,
  Success,
  Error,
}

const ResetPassword: React.FC<IResetPasswordProps> = ({
  revertToLoginMode,
  formData,
}) => {
  const [sendResetPwdRequest] = useMutation(FORGOT_PASSWORD);
  const [emailStatus, setEmailStatus] = useState(EMAIL_STATUS.None);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setEmailStatus(EMAIL_STATUS.Sent);
    sendResetPwdRequest({
      variables: {
        email: formData.Email,
      },
    })
      .then((res) => {
        if (res.data?.forgotPassword?.ok?.toString() === 'true') {
          setEmailStatus(EMAIL_STATUS.Success);
        }
      })
      .catch((e) => setEmailStatus(EMAIL_STATUS.Error));
  };

  return (
    <Box className="w-auto md:w-[420px]">
      {[EMAIL_STATUS.None, EMAIL_STATUS.Sent].includes(emailStatus) ? (
        <>
          <div className="font-sohneKraftig">
            Enter your email and we will handle the rest.
          </div>
          <form
            className="flex flex-col justify-center w-auto mt-8 text-16-26-0.3 align-center md:w-[420px]"
            onSubmit={onSubmit}
          >
            <input
              className="text-16-26-0.3 border form-input"
              type="text"
              placeholder="Email"
              name="Email"
              readOnly
              value={formData.Email}
            />
            <Box>
              <button
                className="float-left ml-auto w-36 h-12 mt-6 text-12-18-0.3 uppercase md:text-16-26-0.3 bg-white border border-gold hover:bg-gold hover:border-gold"
                onClick={revertToLoginMode}
              >
                Cancel
              </button>
              <button
                className="float-right ml-auto w-36 h-12 mt-6 text-12-18-0.3 uppercase md:text-16-26-0.3 bg-gold hover:bg-gold-hover"
                type="submit"
              >
                {emailStatus === EMAIL_STATUS.Sent ? (
                  <CircularProgress />
                ) : (
                  'Submit'
                )}
              </button>
            </Box>
          </form>
        </>
      ) : emailStatus === EMAIL_STATUS.Success ? (
        <>
          <div className="text-center font-sohneKraftig">
            A password reset email was sent to your email address at:
          </div>
          <div className="mt-3 text-center font-sohneKraftig text-gold-darker">
            {formData.Email}
          </div>
        </>
      ) : (
        emailStatus === EMAIL_STATUS.Error && (
          <>
            <div className="text-center font-sohneKraftig">
              Something went wrong. Please try again later.
            </div>
            <button
              className="w-full h-10 mt-6 text-12-18-0.3 uppercase md:text-16-26-0.3 bg-gold hover:bg-gold-hover"
              onClick={revertToLoginMode}
            >
              Back to login
            </button>
          </>
        )
      )}
    </Box>
  );
};

export default ResetPassword;
