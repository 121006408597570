import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Helvetica',
      'sans-serif',
      'Helvetica Bold',
      'Helvetica Oblique',
      'Sohne Test Buch',
      'Sohne Test Halbfett',
      'Sohne Test Kraftig',
      'Sohne Breit Test Halbfett',
      'Sohne Breit Test Buch',
      'Sohne Buch',
      'Sohne Halbfett',
      'Sohne Kraftig',
      'Sohne Breit Halbfett',
      'Sohne Breit Buch',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#cebf7f',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: '268px',
          minWidth: '268px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          boxShadow: 'none',
          minWidth: 0,
          minHeight: 0,
          border: '1px solid #D2CFCA',
          left: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          width: '100%',
          fontSize: '14px',
          maxHeight: '72px',
          height: '100%',
          minWidth: '237px',
          '&.Mui-focused': {
            borderRadius: 0,
            borderWidth: 1,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        // notchedOutline: {
        //   borderWidth: 1,
        //   borderColor: 'none',
        // },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: '100%',
          color: '#000000',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          borderRadius: 0,
          borderWidth: 1,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: '0.75rem',
          position: 'relative',
          display: 'block',
          height: 28,
          width: 28,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#cebf7f',
          background: 'white',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          outline: '0.5px solid transparent',
          borderRadius: 0,
          '&.Mui-checked': {
            backgroundImage: "url('/images/check.svg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          },
          svg: {
            display: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          borderWidth: 1,
          borderRadius: 0,
          borderStyle: 'solid',
          borderColor: '#858585',
          maxWidth: '400px',
          padding: '0',
          marginLeft: '50px',
          background: theme.palette.common.white,
          color: theme.palette.common.black,
        }),
      },
    },
  },
});
