import React from 'react';
import { ThemeProvider } from '@mui/material';

import withCrispChat from '../../hocs/withCrispChat';

import Footer from './Footer';
import FeaturedCondos from './FeaturedCondos';

import { FeaturedResponse } from 'apolloClient/types/Featured';
import { flattenStrapiBulkDataItems } from 'lib/flattenStrapiBulkDataItems';
import { theme } from 'mui/theme';

const Layout: React.FC<{
  featured?: FeaturedResponse;
  children?: React.ReactNode;
  isHomePage?: boolean;
}> = ({ children, featured, isHomePage }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <FeaturedCondos
        isHomePage={isHomePage}
        buildingFromFeatured={flattenStrapiBulkDataItems(
          featured?.data?.attributes?.buildings?.data
        )}
      />
      <Footer isHomePage={isHomePage} />
    </ThemeProvider>
  );
};

export default withCrispChat(Layout);
