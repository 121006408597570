import React, { useEffect } from 'react';
import Image from 'next/legacy/image';
import backToTop from '../public/images/back-to-TOP.svg';
import classnames from 'classnames';

type ToTheTopProps = {
  isSearchPage?: boolean;
};

const ToTheTop: React.FC<ToTheTopProps> = ({ isSearchPage }) => {
  const [inTop, setInTop] = React.useState(true);

  useEffect(() => {
    const handleScroll = () => setInTop(window.scrollY <= 500);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return !inTop ? (
    <button
      onClick={() => scrollTo({ top: 0, behavior: 'smooth' })}
      className={classnames(`fixed right-24 bottom-1 z-60`, {
        ' fixed': !isSearchPage,
        ' hidden md:block': isSearchPage,
      })}
    >
      <Image src={backToTop} alt="back-to-top" />
    </button>
  ) : null;
};

export default ToTheTop;
