import React from 'react';

import { Building } from '../../apolloClient/types';

import { groupByNeighborhood } from 'lib/groupBy';
import classnames from 'classnames';

type Props = {
  buildingFromFeatured?: Building[];
  isHomePage?: boolean;
};

const FeaturedCondos: React.FC<Props> = ({
  buildingFromFeatured,
  isHomePage,
}) => {
  if (!buildingFromFeatured) {
    return null;
  }

  const neighborhoodGroup = groupByNeighborhood(buildingFromFeatured);

  if (!neighborhoodGroup) {
    return null;
  }
  return (
    <section
      className={classnames(
        'text-14-26-0.3 cursor-auto bg-white-light font-sohneBuch',
        {
          'pl-[7px]': isHomePage,
        }
      )}
    >
      <div
        className={classnames(
          'flex mt-[42px] md:mt-[93px] pt-[28px] md:pt-[52px] pl-[20px] md:pl-[47px] pb-6 md:pb-10 lg:pt-[64px] lg:pl-[61px]',
          {
            'container-xl md:mt-0 md:pt-[54px] md:!pl-[41px] lg:!pl-[49px] md:pb-[39px]':
              isHomePage,
            container: !isHomePage,
          }
        )}
      >
        <div className="items-center justify-center hidden lg:w-16 md:flex">
          <div className="w-10 lg:w-16 h-0.5 bg-black" />
        </div>
        <h2 className="justify-center pt-1 md:pt-8px md:pl-[20px] lg:pl-[23px] text-23-29-0.3 tracking-[0.77px] uppercase font-sohneBreitBuch lg:text-30-47-1 ">
          Featured Condos
        </h2>
      </div>
      <div
        className={classnames(
          'grid content-center grid-cols-1 pl-[20px] md:pl-[45px] lg:pl-[54px] pt-[10px] lg:pt-[35px] pb-12 md:grid-cols-2 md:gap-8 md:flex-row',
          {
            'container-xl md:!pl-[39px] lg:!pl-[43px] lg:gap-[37px]':
              isHomePage,
            container: !isHomePage,
          }
        )}
      >
        {Object.entries(neighborhoodGroup).map((neighborhood, index) => {
          const currentNeighborhood =
            neighborhood[1][0].neighborhood.data.attributes;
          return (
            <div key={index} className="mb-1">
              <div className="flex p2-8">
                <div className="relative flex flex-col justify-center mb-2">
                  <div
                    className={`absolute h-10 w-10 -right-2 md:-right-0 lg:-right-6 inline-block transform rotate-45 -translate-y-1/2 top-1/2 bg-white-light`}
                  />
                  <h3
                    className={`pb-2 pt-2.5 pl-5 pr-12 md:mr-8 lg:pr-12 mr-4 lg:mr-0 uppercase font-sohneBreitHalbfett text-14-18-0.3 lg:text-16-21-0.53 tracking-[0.47px] bg-beige min-h-[41px] !py-[10px]`}
                  >
                    <a key={index} href={`/${currentNeighborhood.slug}`}>
                      {currentNeighborhood.name}
                    </a>
                  </h3>
                </div>
              </div>
              <div className="pt-4 pb-[25px] mt-[6px] mb-px text-14-21-0.3 md:pb-6 md:w-full xl:w-[527px] md:tracking-[0.1px] xl:text-16-26-0.3 xl:tracking-[0.1px]">
                {neighborhoodGroup[currentNeighborhood.name].map(
                  (building, index) => {
                    return (
                      <a
                        key={index}
                        href={`/${building.neighborhood.data.attributes.slug}/${building.slug}`}
                      >
                        {index === 0 ? '' : <span className="m-2">|</span>}
                        {building.name}
                      </a>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FeaturedCondos;
