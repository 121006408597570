import { gql } from '@apollo/client';

export const CREATE_FAVORITE_LISTING = gql`
  mutation ($data: FavoriteListingInput!) {
    createFavoriteListing(data: $data) {
      data {
        id
        attributes {
          type
        }
      }
    }
  }
`;

export const DELETE_FAVORITE_LISTING = gql`
  mutation ($id: ID!) {
    deleteFavoriteListing(id: $id) {
      data {
        id
      }
    }
  }
`;
