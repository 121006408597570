import React from 'react';
import Image from 'next/legacy/image';

import arrowDown from '../public/images/arrow-down.svg';

type FlagLabelProps = {
  name: string;
  iconFlagLabel?: boolean;
  imageClassName?: string;
  imageAlt?: string;
  textSize?: string;
  sectionBackground?: string;
};

const FlagLabel: React.FC<FlagLabelProps> = ({
  name,
  iconFlagLabel,
  imageClassName,
  imageAlt,
  textSize = 'bg-beige',
  sectionBackground = 'bg-white',
}) => {
  return (
    <div className="relative pt-[2px] md:pt-0">
      <div
        className={`absolute ${
          name.length > 16
            ? 'h-16 lg:h-16 lg:!top-[50%] w-16 lg:w-16 right-0 md:right-[30px] lg:right-[-40px]'
            : 'h-10 w-10 -right-6'
        } inline-block transform rotate-45 -translate-y-1/2 top-[53%] md:top-[50%] ${sectionBackground}`}
      />
      <h2
        className={`pb-[9.5px] pt-[9.5px] pl-[18px] flex items-center ${
          name.length > 16
            ? 'pr-[40px] md:pr-14 md:mr-[20px] lg:pr-12 mr-[20px] lg:mr-0 w-[90%] md:w-[80%] md:max-w-[365px] lg:w-full'
            : 'pr-10'
        }  sm:pr-12 uppercase font-sohneBreitHalbfett text-16-21-0.53 ${textSize}`}
      >
        {iconFlagLabel && (
          <div className="flex mr-1 transform rotate-180 md:hidden !min-w-[15px] !w-[15px]">
            <Image
              className={`${imageClassName} !min-w-[15px] !w-[15px] !h-full`}
              src={arrowDown}
              alt={imageAlt}
            />
          </div>
        )}
        {name}
      </h2>
    </div>
  );
};

export default FlagLabel;
