import React, { useEffect } from 'react';

type Props = {
  userName?: string;
  email: string;
  closePopup: () => void;
};

const PopUpThanksForRegistration: React.FC<Props> = ({
  userName,
  email,
  closePopup,
}) => {
  const campaignType = !!localStorage.getItem('att') ? 'ppc_user' : 'organic';
  const eventRegistrationSuccessful = () => {
    window.gtag('event', 'registration_successful', {
      event_category: 'registration_successful',
      event_label: 'Success',
      value: 1,
      campaign_type: campaignType,
    });
  };
  useEffect(() => {
    eventRegistrationSuccessful();
  }, [email]);

  return (
    <>
      {userName ? (
        <div id="registration_successful_GA4">
          <div className="py-2 text-18-28-0.3 text-center w-80 font-sohneBreitHalbfett">
            <span className="text-20-28-0.3 text-gold">{userName}, </span>
            thanks for joining the
            <div className="text-20-28-0.3">CondoBlackBook Community!</div>
          </div>
        </div>
      ) : (
        <>
          <div className="py-2 text-18-28-0.3 text-center w-80 font-sohneBreitHalbfett">
            To continue using our website you should verify your account by
            clicking the link we sent you via email.
          </div>
        </>
      )}

      <div className="py-2 text-12-18-0.3 text-center w-80 font-sohneBreitHalbfett">
        <button
          className="w-64 h-12 pt-1 mt-2 uppercase bg-gold hover:bg-gold-hover"
          onClick={closePopup}
        >
          continue
        </button>
      </div>
    </>
  );
};

export default PopUpThanksForRegistration;
