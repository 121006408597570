import { ORIGIN } from 'src/constants';
import noImage from '../../public/images/no_image.svg';

export const defaultPhoneNumber = '(305) 697-7667';
export const defaultPhoneNumberWithCode = `+1 ${defaultPhoneNumber}`;
export const defaultImage = ORIGIN + noImage.src;
export const defaultImageWidth = noImage.width;
export const defaultImageHeight = noImage.height;
export const defaultEmail = 'contact@condoblackbook.com';
export const agentIdSep = 'sniakan';
export const ATT = 'att';
export const UNIT_DISPLAY_COUNTER = 'unitDisplayCounter';
