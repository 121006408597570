import { gql } from '@apollo/client';

export const GET_ME = gql`
  query UserMe {
    me {
      id
      username
      email
      confirmed
      blocked
      isAdmin
      fullName
      phoneNumber
      favoritesMailNotificationFrequency
      favoritesPhoneNotificationFrequency
      notificationPhone
      userType
      country
      city
      address1
      address2
      zipcode
      role {
        id
        name
        description
        type
      }
      assignedUser {
        name
        email
        picture {
          original
        }
      }
    }
    favoriteListings {
      meta {
        pagination {
          total
        }
      }
    }
    savedSearches {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
