import { pick, omit, filter } from 'lodash';

import { BuildingResponse } from 'apolloClient/types/Building';
import {
  FilterRootFieldNameMap,
  QueriesKeys,
  SearchBoxFilters,
  UpdateHandler,
} from '../types';
import { convertBuildingResponse, convertNeighborhood } from './getFilterItem';
import { NeighborhoodResponse } from 'apolloClient/types/Neighborhood';

const getFilterIndex = (
  rootField: FilterRootFieldNameMap,
  { rootId, neighborhoodId }: { neighborhoodId?: number; rootId: number },
  filters: SearchBoxFilters
) => {
  const rootIndex = filters[rootField].findIndex(({ id }) => id === rootId);
  if (rootIndex > -1 && neighborhoodId !== undefined) {
    return {
      rootIndex,
      nhIndex: filters.neighborhoods.findIndex(
        ({ id }) => id === neighborhoodId
      ),
    };
  }
  return { rootIndex, nhIndex: -1 };
};

export const addBuildinLikeFilter = ({
  filters,
  type,
  response,
}: {
  filters: SearchBoxFilters;
  type: 'buildings' | 'addresses';
  response: BuildingResponse;
}): SearchBoxFilters => {
  const { value } = convertBuildingResponse(response);
  const omitField =
    type == 'buildings' ? 'primaryAddress' : 'isPreconstruction';

  if (!value) return filters;

  const newItem = {
    ...omit(value.neighborhood.building, [omitField, 'primaryAddress']),
    name:
      type == 'buildings'
        ? value.neighborhood.building.name
        : value.neighborhood.building.primaryAddress,
  };

  filters[type].push(newItem);

  return filters;
};

export const addNeighborhoodFilter = ({
  filters,
  response,
}: {
  filters: SearchBoxFilters;
  response: NeighborhoodResponse;
}) => {
  const { value } = convertNeighborhood(response);
  filters.neighborhoods.push({
    ...value.neighborhood,
  });
  return filters;
};

export const addFilterHandlersMap: {
  [key in Exclude<QueriesKeys, 'unitUniques'>]: UpdateHandler;
} = {
  counties: async (
    { name, id = -1, slug = '' },
    filters,
    openConfirmDialog
  ) => {
    if (filters.counties.find(({ id: oldId }) => oldId === id)) {
      return filters;
    }

    let isActionAllowed = true;

    if (filters.buildings.length || filters.addresses.length) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.counties.push({ id, name, slug });
      filters.buildings = [];
      filters.addresses = [];
    }

    return filters;
  },
  cities: async ({ name, id = -1, slug = '' }, filters, openConfirmDialog) => {
    if (filters.cities.find(({ id: oldId }) => oldId === id)) {
      return filters;
    }

    let isActionAllowed = true;

    if (filters.buildings.length || filters.addresses.length) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.cities.push({ id, name, slug });
      filters.buildings = [];
      filters.addresses = [];
    }

    return filters;
  },
  neighborhoods: async (
    { name, id = -1, slug = '' },
    filters,
    openConfirmDialog
  ) => {
    if (filters.neighborhoods.find(({ id: oldId }) => oldId === id)) {
      return filters;
    }

    let isActionAllowed = true;

    if (filters.buildings.length || filters.addresses.length) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.neighborhoods.push({ id, name, slug });
      filters.buildings = [];
      filters.addresses = [];
    }

    return filters;
  },
  buildings: async (
    { name, id = -1, slug = '' },
    filters,
    openConfirmDialog
  ) => {
    if (filters.buildings.find(({ id: oldId }) => oldId === id)) {
      return filters;
    }

    let isActionAllowed = true;

    if (
      filters.neighborhoods.length ||
      filters.addresses.length ||
      filters.cities.length ||
      filters.counties.length ||
      filters.zipCodes.length
    ) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.buildings.push({ id, name, slug });
      filters.neighborhoods = [];
      filters.cities = [];
      filters.counties = [];
      filters.zipCodes = [];
      filters.addresses = [];
    }

    return filters;
  },
  addresses: async (
    { name, id = -1, slug = '' },
    filters,
    openConfirmDialog
  ) => {
    if (filters.addresses.find(({ id: oldId }) => oldId === id)) {
      return filters;
    }

    let isActionAllowed = true;

    if (
      filters.neighborhoods.length ||
      filters.buildings.length ||
      filters.cities.length ||
      filters.counties.length ||
      filters.zipCodes.length
    ) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.addresses = [{ id, name, slug }];
      filters.neighborhoods = [];
      filters.cities = [];
      filters.counties = [];
      filters.buildings = [];
      filters.zipCodes = [];
    }

    return filters;
  },
  zipCodes: async ({ name }, filters, openConfirmDialog) => {
    if (filters.zipCodes.find((oldName) => oldName === name)) {
      return filters;
    }

    let isActionAllowed = true;

    if (filters.buildings.length || filters.addresses.length) {
      if (openConfirmDialog) {
        isActionAllowed = await new Promise((res) => {
          openConfirmDialog(res);
        });
      }
    }

    if (isActionAllowed) {
      filters.zipCodes.push(name);
      filters.buildings = [];
      filters.addresses = [];
    }

    return filters;
  },
};

export const deleteFilterHandlersMap: {
  [key in Exclude<QueriesKeys, 'unitUniques'>]: UpdateHandler;
} = {
  cities: async ({ id }, filters) => {
    filters.cities = filters.cities.filter(({ id: oldId }) => id !== oldId);
    return filters;
  },
  counties: async ({ id }, filters) => {
    filters.counties = filters.counties.filter(({ id: oldId }) => id !== oldId);
    return filters;
  },
  neighborhoods: async ({ id }, filters) => {
    filters.neighborhoods = filters.neighborhoods.filter(
      ({ id: oldId }) => id !== oldId
    );
    return filters;
  },
  addresses: async ({ id }, filters) => {
    filters.addresses = filters.addresses.filter(
      ({ id: oldId }) => id !== oldId
    );
    return filters;
  },
  buildings: async ({ id }, filters) => {
    filters.buildings = filters.buildings.filter(
      ({ id: oldId }) => id !== oldId
    );
    return filters;
  },
  zipCodes: async ({ name }, filters) => {
    filters.zipCodes = filters.zipCodes.filter((zipCode) => zipCode !== name);
    return filters;
  },
};
