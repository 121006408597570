import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';

import rightArrow from '../public/images/arrow-right.svg';
import { CircularProgress } from '@mui/material';

export type CbbDropdownOptions = {
  label: string;
  value: string;
};

type CbbDropdownProps = {
  options: Array<string | CbbDropdownOptions>;
  textBeforeSelect?: string;
  nameSelected: string;
  rootClassName?: string;
  selectClassName?: string;
  iconClasses?: string;
  onChange?: (res: string) => void;
  initialSelectedOption?: string | null;
  externalStore?: boolean;
  showPlus?: boolean;
  isLoading?: boolean;
};

const CbbDropdown: React.FC<CbbDropdownProps> = ({
  options,
  textBeforeSelect,
  nameSelected,
  rootClassName,
  selectClassName,
  iconClasses,
  onChange,
  initialSelectedOption,
  externalStore,
  showPlus,
  isLoading,
}) => {
  let initialLabel: string | null = null;
  options.forEach((option) => {
    if (typeof option === 'string' && option === initialSelectedOption) {
      initialLabel = option;
    }
    if (typeof option === 'object' && option.label === initialSelectedOption) {
      initialLabel = option.label;
    }
    if (typeof option === 'object' && option.value === initialSelectedOption) {
      initialLabel = option.label;
    }
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    initialLabel
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: string | CbbDropdownOptions) => () => {
    const normalizeValue = typeof option === 'string' ? option : option.value;
    const label = typeof option === 'string' ? option : option.label;
    setSelectedOption(label);
    setIsOpen(false);
    onChange && onChange(normalizeValue);
  };

  const selectRef = useRef(null);
  useEffect(() => {
    const onClick = ({ target }: { target: any }) => {
      if (
        target !== selectRef.current &&
        target.parentNode?.parentNode?.parentNode !== selectRef.current
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const getSelectedValue = () => {
    if (isLoading) {
      return <CircularProgress color="inherit" size={14} />;
    }

    return externalStore || !selectedOption ? nameSelected : selectedOption;
  };

  return (
    <>
      <div
        onClick={toggle}
        ref={selectRef}
        className={`custom-dropdown flex items-center no-focus relative bg-white box-border justify-between xl:mr-0 pl-3 pr-4 py-2 border cursor-pointer border-beige-dark ${rootClassName}`}
      >
        {textBeforeSelect} {getSelectedValue()}
        {showPlus && getSelectedValue() !== 'Any' && '+'}
        <div className={`w-6 h-6 contents ${iconClasses}`}>
          <Image
            className={` transition duration-300 !pt-[2px] ${
              isOpen ? 'transform rotate-90' : ''
            }`}
            src={rightArrow}
            alt="Toogle Dropdown"
            height={22}
            width={22}
          />
        </div>
        {isOpen && !isLoading && (
          <div
            className={`absolute z-20 flex flex-col  box-border justify-between bg-white top-10 mt-0 border-beige-dark ${selectClassName}`}
          >
            {options.map((option) => (
              <div
                className="px-3 pl-[16px] first:pt-[16px] last:pb-[16px] py-[4px] text-black placeholder-black bg-white border-l border-r cursor-pointer border-beige-dark hover:text-gold-darker first:border-t last:border-b"
                onClick={onOptionClicked(option)}
                key={typeof option === 'string' ? option : option.value}
              >
                {typeof option === 'string' ? option : option.label}
                {showPlus && option !== 'Any' && '+'}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CbbDropdown;
