import { Building, BuildingsResponse, Neighborhood } from 'apolloClient/types';
import { CitiesResponse, City } from 'apolloClient/types/City';
import { CountiesResponse, County } from 'apolloClient/types/County';
import { NeighborhoodsResponse } from 'apolloClient/types/Neighborhood';
import { ListingCollectionResponse } from 'apolloClient/types/Units';

export type AutocompleteResponse = {
  counties: CountiesResponse;
  cities: CitiesResponse;
  neighborhoods: NeighborhoodsResponse;
  buildings: BuildingsResponse;
  preconstruction: BuildingsResponse;
  addresses: BuildingsResponse;
  zipcodes: BuildingsResponse;
  unitUniques: ListingCollectionResponse;
};

export type PaginableFields = Exclude<
  keyof AutocompleteResponse,
  'cities' | 'counties'
>;

export enum FilterRootFieldNameMap {
  city = 'cities',
  county = 'counties',
  neighborhood = 'neighborhoods',
  building = 'buildings',
  address = 'addresses',
}

export type QueriesKeys =
  | 'counties'
  | 'cities'
  | 'neighborhoods'
  | 'buildings'
  | 'addresses'
  | 'zipCodes';

export type AutocompleteCategories =
  | QueriesKeys
  | 'preconstruction'
  | 'recent'
  | 'unitUniques';

export type SearchItem = {
  id?: number;
  label: string;
  category: AutocompleteCategories;
  link?: string;
  slug?: string;
};

export type UpdateValue = {
  id?: number;
  slug?: string;
  name: string;
  city?: number;
  county?: number;
  neighborhood?: number;
};

export type UpdateHandler = (
  value: UpdateValue,
  filters: SearchBoxFilters,
  openConfirmDialog?: (closeHandler: (value: boolean) => void) => void
) => Promise<SearchBoxFilters>;

export type RootField =
  | 'city'
  | 'county'
  | 'neighborhood'
  | 'building'
  | 'address';

export type BuildingsFilter = Pick<Building, 'slug' | 'id' | 'name'> & {
  isPreconstruction?: boolean;
};

export type NeighborhoodsFilter = Pick<Neighborhood, 'id' | 'slug' | 'name'>;

export type CitiesFilter = City;
export type CountiesFilter = County;

export type SearchBoxFilters = {
  counties: CountiesFilter[];
  cities: CitiesFilter[];
  zipCodes: string[];
  neighborhoods: NeighborhoodsFilter[];
  buildings: BuildingsFilter[];
  addresses: BuildingsFilter[];
};

export const initialSearchBoxFilters: SearchBoxFilters = {
  cities: [],
  counties: [],
  zipCodes: [],
  neighborhoods: [],
  buildings: [],
  addresses: [],
};

export type DataForAutocompleteInterface = {
  [key in AutocompleteCategories]: string[];
};
