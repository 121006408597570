import { gql } from '@apollo/client';

export const LOAD_SEARCH_INPUT_ITEMS = gql`
  query (
    $loadAddresses: Boolean!
    $loadZipcodes: Boolean!
    $loadBuildings: Boolean!
    $loadListings: Boolean!
    $loadOnlyPaginated: Boolean!
    $loadPreconstruction: Boolean!
    $loadNeighborhoods: Boolean!
    $addressesPage: Int
    $zipcodesPage: Int
    $buildingsPage: Int
    $listingsPage: Int
    $preconstructionPage: Int
    $neighborhoodsPage: Int
    $nhFilters: NeighborhoodFiltersInput
    $buildingFilters: BuildingFiltersInput
    $preconstructionFilters: BuildingFiltersInput
    $addressesFiltersFilters: BuildingFiltersInput
    $zipcodesFiltersFilters: BuildingFiltersInput
    $listingFilters: ListingFiltersInput
    $citiesFilter: CityFiltersInput
    $countiesFilter: CountyFiltersInput
  ) {
    cities(filters: $citiesFilter) @skip(if: $loadOnlyPaginated) {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    counties(filters: $countiesFilter) @skip(if: $loadOnlyPaginated) {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    neighborhoods(
      pagination: { pageSize: 10, page: $neighborhoodsPage }
      filters: $nhFilters
    ) @include(if: $loadNeighborhoods) {
      data {
        id
        attributes {
          slug
          name
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
    buildings(
      pagination: { pageSize: 10, page: $buildingsPage }
      filters: $buildingFilters
    ) @include(if: $loadBuildings) {
      data {
        id
        attributes {
          name
          slug
          primaryAddress
          mappingAddresses {
            zipCode
          }
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
    preconstruction: buildings(
      pagination: { pageSize: 10, page: $preconstructionPage }
      filters: $preconstructionFilters
    ) @include(if: $loadPreconstruction) {
      data {
        id
        attributes {
          name
          slug
          primaryAddress
          mappingAddresses {
            zipCode
          }
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
    addresses: buildings(
      pagination: { pageSize: 10, page: $addressesPage }
      filters: $addressesFiltersFilters
    ) @include(if: $loadAddresses) {
      data {
        id
        attributes {
          name
          slug
          primaryAddress
          mappingAddresses {
            zipCode
          }
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
    zipcodes: buildings(
      pagination: { pageSize: 10, page: $zipcodesPage }
      filters: $zipcodesFiltersFilters
    ) @include(if: $loadZipcodes) {
      data {
        id
        attributes {
          name
          slug
          primaryAddress
          mappingAddresses {
            zipCode
          }
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
    unitUniques: listings(
      pagination: { pageSize: 10, page: $listingsPage }
      filters: $listingFilters
    ) @include(if: $loadListings) {
      data {
        attributes {
          mlsListingId
          propertyTypeCode
          unit {
            data {
              attributes {
                unique
                building {
                  data {
                    attributes {
                      slug
                      neighborhood {
                        data {
                          attributes {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          pageCount
          page
        }
      }
    }
  }
`;

export const GET_NEIGHBORHOOD_FILTER = gql`
  query ($id: ID!) {
    neighborhood(id: $id) {
      data {
        id
        attributes {
          name
          slug
          city {
            data {
              id
              attributes {
                name
                slug
                county {
                  data {
                    id
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BUILDING_FILTER = gql`
  query ($id: ID!) {
    building(id: $id) {
      data {
        id
        attributes {
          name
          slug
          isPreconstruction
          primaryAddress
          neighborhood {
            data {
              id
              attributes {
                name
                slug
                city {
                  data {
                    id
                    attributes {
                      name
                      slug
                      county {
                        data {
                          id
                          attributes {
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SEARCHBOX_URL_FILTERS = gql`
  query (
    $addresses: [String]
    $buildings: [String]
    $cities: [String]
    $counties: [String]
    $neighborhoods: [String]
    $loadAddresses: Boolean!
    $loadBuildings: Boolean!
    $loadCities: Boolean!
    $loadCounties: Boolean!
    $loadNeighborhoods: Boolean!
  ) {
    cities(filters: { slug: { in: $cities } }) @include(if: $loadCities) {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    counties(filters: { slug: { in: $counties } }) @include(if: $loadCounties) {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    neighborhoods(filters: { slug: { in: $neighborhoods } })
      @include(if: $loadNeighborhoods) {
      data {
        id
        ...NeighborhoodData
      }
    }
    buildings(filters: { slug: { in: $buildings } })
      @include(if: $loadBuildings) {
      data {
        id
        ...BuildingData
      }
    }
    addresses: buildings(filters: { primaryAddress: { in: $addresses } })
      @include(if: $loadAddresses) {
      data {
        id
        ...BuildingData
      }
    }
  }
  fragment BuildingData on BuildingEntity {
    attributes {
      name
      slug
      isPreconstruction
      primaryAddress
      neighborhood {
        data {
          id
          ...NeighborhoodData
        }
      }
    }
  }

  fragment NeighborhoodData on NeighborhoodEntity {
    attributes {
      name
      slug
      city {
        data {
          id
          attributes {
            name
            slug
            county {
              data {
                id
                attributes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
