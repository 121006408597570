export const ORIGIN = process.env.ORIGIN;
export const TEST_ORIGIN = process.env.TEST_ORIGIN;
export const STAGE_ORIGIN = process.env.STAGE_ORIGIN;
export const DEFAULT_REVALIDATE_INTERVAL = 600; //600 sec (10min)
export const IS_PROD = process.env.IS_PROD; // may be mistake
export const GA_PRIVATE_KEY = process.env.GA_PRIVATE_KEY;
export const GA_CLIENT_EMAIL = process.env.GA_CLIENT_EMAIL;
export const GA_CLIENT_ID = process.env.GA_CLIENT_ID;
export const GA_PROPERTY_ID = process.env.GA_PROPERTY_ID;
export const GA_PROPERTY_LEGACY_ID = process.env.GA_PROPERTY_LEGACY_ID;
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

// finally enable this for Prod environment
// while non-production - set to false directly
export const IS_FACEBOOK_PIXEL_ENABLED = false;
// export const IS_FACEBOOK_PIXEL_ENABLED = process.env.IS_PRODUCTION;
