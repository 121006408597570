import { gql } from '@apollo/client';

export const AUTH_LOGIN = gql`
  mutation ($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
        email
        confirmed
        blocked
        isAdmin
        fullName
        assignedLead {
          id
        }
        role {
          id
          name
          description
          type
        }
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  query ($email: String!) {
    verifyEmail(email: $email) {
      ok
      isLostLegacyPwd
    }
  }
`;

export const VERIFY_RECAPTCHA = gql`
  query ($token: String!) {
    verifyRecaptcha(token: $token) {
      ok
      score
    }
  }
`;

export const REGISTER = gql`
  mutation ($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        username
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
    }
  }
`;

export const SEND_EMAIL_CONFIRMATION = gql`
  mutation ($email: String!) {
    sendEmailConfirmation(email: $email) {
      ok
    }
  }
`;
