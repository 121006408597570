import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { client } from 'apolloClient/client';
import { UPDATE_ME } from 'apolloClient/mutations/me';
import { GET_ME } from 'apolloClient/queries/me';
import { Response } from 'apolloClient/types/common';
import { FavoriteListingsResponse } from 'apolloClient/types/FavoriteListing';
import { SavedSearchesResponse } from 'apolloClient/types/Searches';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import {
  AuthenticatedUserInfo,
  AuthenticatedUserState,
} from '../../apolloClient/types';
import { initialAuthData } from '../../src/constants/authConstants';
import {
  getAuthDataFromCookies,
  getAuthDataFromSessionStorage,
  updateAuthDataInCookies,
} from '../../src/utils/authUtils';
import {
  StrapiAgent,
  StrapiAgentResponse,
} from '../../apolloClient/types/Agents';
import { VERIFY_EMAIL } from 'apolloClient/mutations/auth';
import { useRouter } from 'next/router';
import { removeQueryParams } from 'src/utils/utils';

type TNOOP = () => void;

interface AuthContextInterface {
  authData: AuthenticatedUserInfo;
  onLoggedIn: null | ((info: AuthenticatedUserInfo) => void);
  me?: null | AuthenticatedUserState;
  agent: StrapiAgent | null;
  logout: TNOOP;
  updateMe: (user: Partial<AuthenticatedUserState>) => Promise<void>;
  isLogged: boolean;
  headerPopupOpen: boolean;
  openHeaderPopup: (params?: {
    successHandler?: TNOOP;
    finnallyHandler?: TNOOP;
    keepOpen?: boolean;
  }) => void;
  closeHeaderPopup: TNOOP;
  onSuccess: TNOOP;
  onFinnaly: TNOOP;
  keepOpen: boolean;
  setMe: (me: AuthenticatedUserState) => void;
  searchesCount: number;
  favoritesCount: number;
  getActualAuthData: () => AuthenticatedUserInfo;
  isLoginTriggered: boolean;
  isAuthProcessed: boolean;
}

const NOOP = () => {};

const getAuthData = () => {
  const sessionStorageData = getAuthDataFromSessionStorage();
  if (sessionStorageData.jwt) {
    return sessionStorageData;
  }
  return getAuthDataFromCookies(document.cookie);
};

export const AuthContext = createContext<AuthContextInterface>({
  agent: null,
  authData: initialAuthData,
  onLoggedIn: null,
  logout: NOOP,
  updateMe: async () => {},
  isLogged: false,
  headerPopupOpen: false,
  openHeaderPopup: NOOP,
  closeHeaderPopup: NOOP,
  onSuccess: NOOP,
  onFinnaly: NOOP,
  keepOpen: false,
  setMe: NOOP,
  searchesCount: 0,
  favoritesCount: 0,
  getActualAuthData: () => getAuthData(),
  isLoginTriggered: false,
  isAuthProcessed: false,
});

interface Props {
  authDataFromCookie: AuthenticatedUserInfo;
  children?: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children, authDataFromCookie }) => {
  const [authData, setAuthData] =
    useState<AuthenticatedUserInfo>(authDataFromCookie);

  const [isLoginTriggered, setLoginTriggered] = useState(false);

  const [me, setMe] = useState<AuthenticatedUserState | null>();
  const [agent, setAgent] = useState<StrapiAgent | null>(null);

  const [isKeepOpen, setKeepOpen] = useState(false);
  const [isAuthProcessed, setIsAuthProcessed] = useState(false);

  const [favoritesCount, setFavoritesCount] = useState(0);
  const [searchesCount, setSearchesCount] = useState(0);
  const router = useRouter();

  useEffect(() => {
    if (router.query.login) {
      setHeaderPopupOpen(true);
      removeQueryParams(['login'], router);
    }
  }, [router]);

  useEffect(() => {
    if (!authData?.user?.id) {
      setFavoritesCount(0);
      setSearchesCount(0);
      setMe(null);
      setAgent(null);
      setIsAuthProcessed(true);
      return;
    }

    const refetchMe = async () => {
      const {
        data: { me, favoriteListings, savedSearches, agent },
      } = await client.query<{
        me: AuthenticatedUserState;
        favoriteListings: Pick<FavoriteListingsResponse, 'meta'>;
        savedSearches: Pick<SavedSearchesResponse, 'meta'>;
        agent: StrapiAgentResponse;
      }>({
        query: GET_ME,
      });
      setMe(me);
      agent?.data?.id && setAgent(flattenStrapiDataItem(agent.data));
      savedSearches?.meta?.pagination?.total &&
        setSearchesCount(savedSearches.meta.pagination.total);
      favoriteListings?.meta?.pagination?.total &&
        setFavoritesCount(favoriteListings?.meta?.pagination?.total);
      setIsAuthProcessed(true);
    };
    refetchMe();
  }, [authData?.user?.id]);

  const updateMe = async (updateFields: Partial<AuthenticatedUserState>) => {
    if (me?.email !== updateFields.email) {
      const {
        data: {
          verifyEmail: { ok: isEmailExists },
        },
      } = await client.query({
        query: VERIFY_EMAIL,
        fetchPolicy: 'no-cache',
        variables: {
          email: updateFields.email,
        },
      });

      if (isEmailExists) throw new Error(`Email already exists`);
    }

    const response = await client.mutate<
      {
        updateUsersPermissionsUser: Response<
          Omit<AuthenticatedUserState, 'id'>
        >;
      },
      { id: number; data: Partial<AuthenticatedUserState> }
    >({
      mutation: UPDATE_ME,
      variables: {
        id: authData.user.id || -1,
        data: updateFields,
      },
    });
    response.data;
    const user = flattenStrapiDataItem(
      response.data?.updateUsersPermissionsUser.data
    );

    setMe(user);
  };

  useEffect(() => {
    setAuthData(authDataFromCookie);
  }, [authDataFromCookie]);

  function logout() {
    setAuthData(initialAuthData);
    setMe(null);
    updateAuthDataInCookies(initialAuthData);
    setSuccessHandler(null);
    setFinnalyHandler(null);
  }

  const [headerPopupOpen, setHeaderPopupOpen] = useState(false);
  const [successHandler, setSuccessHandler] = useState<(() => void) | null>();
  const [finnalyHandler, setFinnalyHandler] = useState<(() => void) | null>();

  const onSuccess = () => {
    successHandler && successHandler();
    setSuccessHandler(null);
    setKeepOpen(false);
    setHeaderPopupOpen(false);
  };

  const onFinnaly = () => {
    finnalyHandler && finnalyHandler();
    setFinnalyHandler(null);
  };

  const onLoggedIn = (info: AuthenticatedUserInfo) => {
    setAuthData(info);
    setLoginTriggered(true);
  };

  const openHeaderPopup = useCallback(
    (params?: {
      successHandler?: TNOOP;
      finnallyHandler?: TNOOP;
      keepOpen?: boolean;
    }) => {
      setHeaderPopupOpen(true);
      if (params?.successHandler)
        setSuccessHandler(() => params.successHandler);
      if (params?.finnallyHandler)
        setFinnalyHandler(() => params.finnallyHandler);
      if (params?.keepOpen) setKeepOpen(true);
    },
    []
  );

  const closeHeaderPopup = useCallback(() => {
    if (isKeepOpen) return;
    setHeaderPopupOpen(false);
    finnalyHandler && finnalyHandler();
  }, [finnalyHandler, isKeepOpen]);

  const getActualAuthData = useCallback(() => getAuthData(), []);

  return (
    <AuthContext.Provider
      value={{
        agent,
        searchesCount,
        favoritesCount,
        authData,
        onLoggedIn,
        logout,
        updateMe,
        me,
        isLogged: Boolean(authData?.user?.id),
        headerPopupOpen,
        setMe,
        openHeaderPopup,
        closeHeaderPopup,
        onSuccess,
        onFinnaly,
        keepOpen: isKeepOpen,
        getActualAuthData,
        isLoginTriggered,
        isAuthProcessed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const useOnLoggedIn = (
  callBack: (authData: AuthenticatedUserInfo) => void
) => {
  const { isLogged, authData } = useAuth();

  useEffect(() => {
    if (isLogged) {
      callBack(authData);
    }
  }, [isLogged]);
};

export default AuthProvider;
