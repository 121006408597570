import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  NewSavedSearches,
  SavedSearches,
  SavedSearchesResponse,
} from 'apolloClient/types/Searches';
import {
  ApolloQueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { GET_SAVED_SEARCHES } from 'apolloClient/queries/savedSearches';
import { Entity, Pagination } from 'apolloClient/types/common';
import {
  CREATE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH,
} from 'apolloClient/mutations/savedSearches';
import useSnackbar from 'components/SnackBar/useSnackbar';
import { useAuth } from 'components/Auth/AuthProvider';
import { FiltersValues } from 'components/UnitsFilters/types';

export type SaveSearchVariables = {
  alertFrequency: string;
  searchParams: Partial<FiltersValues>;
  name: string | null;
};

type Response = { savedSearches: SavedSearchesResponse };

type FetchResult = ApolloQueryResult<Response>;

export type SavedSearchesContext = {
  searches: Entity<SavedSearches>[];
  deleteSearch: (id?: number) => Promise<void>;
  createSearch: (data: NewSavedSearches) => Promise<void>;
  updateSearch: (
    id: number,
    data: Partial<SaveSearchVariables>
  ) => Promise<void>;
  getSearches: (page?: number) => void;
  loading?: boolean;
  pagination: Pagination;
  count: number;
};

const NOOP = () => new Promise<void>((res) => res());

export const SavedSearchesContext = createContext<SavedSearchesContext>({
  searches: [],
  deleteSearch: NOOP,
  createSearch: NOOP,
  updateSearch: NOOP,
  getSearches: NOOP,
  pagination: { total: 0 },
  count: 0,
});

const paginationPageSize = 6;

const SavedSearchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showSuccess } = useSnackbar();
  const [refetch, { data, updateQuery, loading }] = useLazyQuery<
    { savedSearches: SavedSearchesResponse },
    { page: number; pageSize: number }
  >(GET_SAVED_SEARCHES, {
    variables: { page: 1, pageSize: paginationPageSize },
    fetchPolicy: 'network-only',
  });
  const [createSavedSearch] = useMutation(CREATE_SAVED_SEARCH);
  const [deleteSavedSearch] = useMutation(DELETE_SAVED_SEARCH);
  const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH);
  const { searchesCount, isLogged } = useAuth();

  const [count, steCount] = useState(0);

  useEffect(() => {
    if (searchesCount) {
      steCount(searchesCount);
    }
  }, [searchesCount]);

  useEffect(() => {
    if (!isLogged && data) {
      updateQuery(() => {
        return {
          savedSearches: {
            data: [],
          },
        };
      });
    }
  }, [isLogged, updateQuery, data]);

  const getSearches = useCallback(
    (page?: number) => {
      refetch({
        variables: {
          page: page || 1,
          pageSize: paginationPageSize,
        },
      });
    },
    [refetch]
  );

  const createSearch = async (data: NewSavedSearches) => {
    const { sortOption, ...filtered } = data;
    const res = await createSavedSearch({
      variables: {
        data: filtered,
      },
    }).then(() => steCount(count + 1));
    showSuccess({ message: 'Search created successfully' });
    return res;
  };

  const deleteSearch = async (id?: number) => {
    const res = await deleteSavedSearch({ variables: { id } }).then(() => {
      steCount(count - 1);
      refetch();
    });
    showSuccess({ message: 'Search deleted successfully' });
    return res;
  };

  const pagination = data?.savedSearches?.meta?.pagination || {};

  const updateSearch = async (
    id: number,
    data: Partial<SaveSearchVariables>
  ) => {
    const res = await updateSavedSearch({ variables: { id, data } }).then(() =>
      refetch({
        variables: { page: pagination.page || 1, pageSize: paginationPageSize },
      })
    );
    showSuccess({ message: 'Search updated successfully' });
    return;
  };

  return (
    <SavedSearchesContext.Provider
      value={{
        getSearches,
        searches: data?.savedSearches?.data || [],
        createSearch,
        deleteSearch,
        updateSearch,
        loading,
        pagination,
        count,
      }}
    >
      {children}
    </SavedSearchesContext.Provider>
  );
};

export const useSavedSearch = () => useContext(SavedSearchesContext);

export default SavedSearchProvider;
