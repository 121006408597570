import { gql } from '@apollo/client';

export const GET_FAVORITE_LISTINGS = gql`
  query FavoriteListings($page: Int!, $pageSize: Int!, $userId: ID!) {
    favoriteListings(pagination: { pageSize: $pageSize, page: $page }) {
      data {
        id
        attributes {
          type
          unit {
            data {
              id
              attributes {
                saleListing {
                  ...Listing
                }
                rentListing {
                  ...Listing
                }
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                unique
                building {
                  data {
                    attributes {
                      name
                      slug
                      neighborhood {
                        data {
                          attributes {
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
        }
      }
    }
  }
  fragment Listing on ListingEntityResponse {
    data {
      id
      attributes {
        images {
          data {
            attributes {
              url
              name
            }
          }
        }
        propertyTypeCode
        statusCode
        closeDate
        mlsListingId
        price
        bedsTotal
        bathsFull
        sqft
        yearBuilt
        unitNumber
        address {
          streetName
          streetNumber
          streetType
          streetDirection
        }
      }
    }
  }
`;
