import { gql } from '@apollo/client';

export const FEATURED_CONDOS = gql`
  query featuredCondos {
    featured {
      data {
        attributes {
          buildings(
            pagination: { pageSize: 1000 }
            filters: { publishedAt: { notNull: true } }
          ) {
            data {
              attributes {
                name
                slug
                neighborhood {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_FEATURED_BLOG = gql`
  query {
    featured {
      data {
        id
        attributes {
          blog {
            data {
              id
              attributes {
                mainImage {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
                title
                slug
                publishedDate
                createdBy
                author
                shortDescription
              }
            }
          }
        }
      }
    }
  }
`;
