import React, { useMemo, useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import classnames from 'classnames';
import Box from '@mui/material/Box';

import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import { Response } from 'apolloClient/types/common';
import { isNotEmpty } from 'src/utils/utils';
import { MAX_INVALID_PASSWORD_ATTEMPTS } from 'constants/auth/login';

import {
  FormDataInterface,
  SubmitStateInterface,
  FieldsValidInterface,
} from './interfaces';

interface LoginModeProps {
  data:
    | {
        lastListingUpdate: Response<Record<'lastListingUpdate', string>>;
      }
    | undefined;
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: FormDataInterface;
  submitState: SubmitStateInterface;
  fieldsValid: FieldsValidInterface;
  authFormLoading: boolean;
  goToResetPasswordMode: () => void;
  revertToStartStage: () => void;
}

const LoginMode: React.FC<LoginModeProps> = ({
  data,
  onFormSubmit,
  formData,
  handleChange,
  submitState,
  fieldsValid,
  authFormLoading,
  goToResetPasswordMode,
  revertToStartStage,
}) => {
  const [invalidPasswordAttempts, setInvalidPasswordAttempts] = useState(0);

  const updatedDiff = useMemo(() => {
    if (data) {
      const { lastListingUpdate } = flattenStrapiDataItem(
        data?.lastListingUpdate?.data
      );
      if (lastListingUpdate) {
        return formatDistanceToNow(new Date(lastListingUpdate));
      }
    }
  }, [data]);

  useEffect(() => {
    if (formData.isInvalidPassword) {
      const newAttempts = invalidPasswordAttempts + 1;
      setInvalidPasswordAttempts(newAttempts);
      if (newAttempts >= MAX_INVALID_PASSWORD_ATTEMPTS) {
        goToResetPasswordMode();
      }
    }
  }, [formData.isInvalidPassword]);

  return (
    <>
      <div className="w-auto md:w-[420px]">
        <div className="mb-4">
          Search all Miami condos, exclusive listings, and save your searches
          and favorites.
        </div>
        <div className="font-sohneKraftig">
          Last listings update {updatedDiff || 'less than minute'} ago
          {/* TODO: calculate 'friendly time' and join the logic when RETS job records date/time in settings; also show this date/time in footer */}
        </div>
      </div>
      <form
        className="flex flex-col justify-center w-auto mt-8 text-16-26-0.3 align-center md:w-[420px]"
        onSubmit={onFormSubmit}
      >
        <input
          className="text-16-26-0.3 border form-input"
          type="text"
          placeholder="Email"
          name="Email"
          readOnly={formData.showPassword}
          value={formData.Email}
          onChange={handleChange}
        />
        {submitState.isTouched && !fieldsValid.Email && (
          <span className="field-validation-error">
            <span className="pl-3 text-red">
              Email is
              {isNotEmpty(formData.Email) ? ' not valid' : ' required'}
            </span>
          </span>
        )}
        {formData.showPassword && (
          <>
            <input
              className="text-16-26-0.3 border form-input"
              type="password"
              placeholder="Password"
              name="Password"
              value={formData.Password}
              onChange={handleChange}
            />
            <Box justifyContent="space-between" alignItems="center">
              <button
                type="button"
                className="float-left mt-2 text-gold-darker mr-o w-14 hover:underline focus:underline"
                onClick={revertToStartStage}
              >
                Back
              </button>
              <button
                type="button"
                className="float-right mt-2 ml-auto text-gold-darker mr-o w-36 hover:underline focus:underline"
                onClick={goToResetPasswordMode}
              >
                Forgot password?
              </button>
            </Box>
          </>
        )}
        {formData.showPassword &&
          submitState.isTouched &&
          !fieldsValid.Password && (
            <span className="field-validation-error">
              <span className="pl-3 text-red">Password is required</span>
            </span>
          )}
        {formData.showPassword &&
          submitState.isTouched &&
          formData.isInvalidPassword && (
            <span className="field-validation-error">
              <span className="pl-3 text-red">
                The password provided is incorrect.
              </span>
            </span>
          )}
        {formData.showPassword && formData.isNeedShowLegacyMessage && (
          <div className="mt-2 text-14-26-0.3">
            {formData.isNeedPwdReset ? (
              <span className="field-validation-error">
                <span className="text-red">
                  For security purposes, we are asking that all users reset
                  their password. Please click 'Forgot password' button above to
                  have a password reset link sent to your email
                </span>
              </span>
            ) : (
              <span className="field-validation-error">
                <span>
                  To login, please use the password you used when registering.
                  If the password was changed and you don't remember it or the
                  entered doesn't work, please reset your password
                </span>
              </span>
            )}
          </div>
        )}
        <button
          id={`${
            formData.showPassword ? 'Login_Send' : 'Sign_Up_Continue_With_Email'
          }`}
          className={classnames(
            'w-full h-10 mt-6 text-12-18-0.3 uppercase md:text-16-26-0.3',
            {
              'bg-gold-hover hover:bg-gold-hover': authFormLoading,
              'bg-gold hover:bg-gold-hover': !authFormLoading,
            }
          )}
          type="submit"
        >
          {formData.showPassword ? 'Login' : 'Continue with email'}
        </button>
        {/* We will work on this section in phase 2 */}
        {/* <div className="mt-4 text-gray">Or sign in with</div>
        <button className="button-continue-with">
            <Image src={iconGoogle} alt="iconGoogle" />
            <span className="pl-10">Continue with Google</span>
        </button>
        <button className="button-continue-with">
            <Image src={iconApple} alt="iconApple" />
            <span className="pl-10">Continue with Apple</span>
        </button>
        <button className="button-continue-with">
            <Image src={iconFacebook} alt="iconFacebook" />
            <span className="pl-10">Continue with Facebook</span>
        </button> */}
        <div className="mt-6 text-14-26-0.3">
          By signing up, I agree to CondoBlackBook
          <a
            target="_blank"
            className="pl-1 text-gold-darker"
            href="/terms-of-use"
          >
            Terms of use
          </a>
        </div>
      </form>
    </>
  );
};

export default LoginMode;
