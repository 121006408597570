import React from 'react';
import { AlertProps } from '@mui/material';

export type SpecifficAlertIntentHandler = (props: {
  message: string;
  props?: Omit<AlertProps, 'severity'>;
}) => void;

export type AlertHandler = (props: {
  message: string;
  props?: AlertProps;
}) => void;

export type SnackbarContextType = {
  showSnackbar: AlertHandler;
  showError: SpecifficAlertIntentHandler;
  showSuccess: SpecifficAlertIntentHandler;
  showWarning: SpecifficAlertIntentHandler;
  showInfo: SpecifficAlertIntentHandler;
};

const NOOP = () => {};

export default React.createContext<SnackbarContextType>({
  showSnackbar: NOOP,
  showError: NOOP,
  showSuccess: NOOP,
  showWarning: NOOP,
  showInfo: NOOP,
});
